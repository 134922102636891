import axios from "axios";

const instance = axios.create({
    baseURL: process.env.VUE_APP_URL_API,
    headers: {
        Accept: 'application/json'
    }
})

instance.interceptors.request.use(function (config){
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
})

instance.interceptors.response.use(function (response) {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry){
        originalRequest._isRetry = true;
        try {
            const {data} = await axios.get(process.env.VUE_APP_URL_API + "/api/v1/refresh",  {withCredentials: true});
            localStorage.setItem('token', data.data.access_token)
            return instance.request(originalRequest)
        }catch (e){
            console.log("Not authorized")
        }
    }
    throw error;
});


export default instance
