<template>
    <section class="menu">
        <Header/>
        <PartnersLogo />
    </section>
    <section class="each__page">
        <router-view/>
    </section>
    <Footer/>
</template>

<script>
import Footer from "../components/include/Footer";
import Header from "../components/include/Header";
import PartnersLogo from "../components/include/PartnersLogo";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "MainLayout",
    components: {
        PartnersLogo,
        Header,
        Footer,
    },
    created() {
        this.$store.dispatch('auth/currentUser')
        this.checkUserState();
    },
    computed: {
        ...mapGetters({
            loggedIn: 'auth/loggedIn',
        }),
        currentUser: {
            get() {
                return this.$store.state.auth.userDetails
            },
        },
    },
    methods: {
        ...mapActions({
            checkUserState: 'auth/setLoggedInState',
        }),
    },
}
</script>

<style scoped>

</style>
