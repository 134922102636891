<template>
    <div class="admin_header">
        <div class="admin_logo">
            <i class="fa fa-lock" aria-hidden="true"></i>
            <span>CarColors</span>
        </div>
        <a href="#" class="admin_nav-trigger" v-on:click="isActive = !isActive;"><span></span></a>
    </div>
    <div class="admin_side-nav" :class="{ visible: isActive }">
        <div class="admin_logo">
            <i class="fa fa-cog fa-3x fa-fw" aria-hidden="true"></i>
            <span>CarColors</span>
        </div>
        <nav>
            <ul>
                <li>
                    <router-link to="/admin/dashboard" >
                        <span><i class="fas fa-user"></i></span>
                        <span>Գլխավոր</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/admin/products" >
                        <span><i class="fa fa-list-alt"></i></span>
                        <span>Ապրանքներ</span>
                    </router-link >
                </li>
                <li>
                    <router-link to="/admin/sales">
                        <span><i class="fa fa-money-check-alt"></i></span>
                        <span>Զեղչեր</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/admin/cart">
                        <span><i class="fa fa-shopping-cart"></i></span>
                        <span>Պատվերներ</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/admin/messages">
                        <span><i class="fa fa-bell"></i></span>
                        <span>Նամակներ</span>
                    </router-link>
                </li>
                <li>
                    <a style="color: white; cursor: pointer" @click.prevent="Logout">
                        <span><i class="fa fa-sign-out"></i></span>
                        <span>Դուրս գալ</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "LeftSidebar",
    data: () => ({
       isActive: false,
    }),
    methods:{
        Logout(){
            this.$store.dispatch('auth/logout')
        }
    },
    mounted() {
        // console.log(this.user)
    }
}
</script>

