<template>
    <div class="error-alert" v-for="(value,index) in registrationErrorsFunction" :key="index">
        <i class="fa fa-times-circle"></i> {{ value }}
    </div>
</template>
-
<script>
export default {
    name: "RegistrationErrors",
    props: ['registrationErrors'],
    computed: {
        registrationErrorsFunction() {
            let registrationErrorsArray = Object.values(this.registrationErrors);
            registrationErrorsArray = registrationErrorsArray.flat()
            for (let i = 0; i < registrationErrorsArray.length; i++) {
                if (registrationErrorsArray[i] == 'Name is required') {
                    registrationErrorsArray[i] = this.$t('name.required')
                }
                if (registrationErrorsArray[i] == 'Lastname is required') {
                    registrationErrorsArray[i] = this.$t('lastname.required')
                }
                if (registrationErrorsArray[i] == 'Email is required') {
                    registrationErrorsArray[i] = this.$t('email.required')
                } else if (registrationErrorsArray[i] == 'Email must be a valid email address.') {
                    registrationErrorsArray[i] = this.$t('email.email')
                }
                if (registrationErrorsArray[i] == 'Password is required') {
                    registrationErrorsArray[i] = this.$t('password.required')
                }else if(registrationErrorsArray[i] == 'The password must be at least 8 characters'){
                    registrationErrorsArray[i] = this.$t('password.min')
                }
            }
            return registrationErrorsArray;
        },
    },
}
</script>

