<template>
    <div id="nav" class="menu">
        <div class="adapt-menu-mobile">
            <div>
                <router-link class="menu__items" to="/">
                    <img class="logo" :src="require('/images/logo.png').default"/>
                </router-link>
            </div>

            <div class="menu__dropdown">
                <router-link class="menu__items" to="/cart">
                    <div class="cart_div">
                        <div v-if="this.cartLength > 0" class="count_cart">{{ this.cartLength }}</div>
                        <img :src="require('/images/menu-items/shp.png').default" alt="SHOP"/>
                    </div>
                </router-link>
                <div @click="openMenu" class="menu__btn_open">
                    <img :src="require('/images/button-icons/top-menu.png').default"/>
                    <div class="menu_txt">MENU</div>
                </div>
            </div>
        </div>

        <div ref="menuToggleLeftSide" class="menu__left_side">
            <div class="adapt-logo">
                <router-link class="menu__items" to="/"
                ><img class="logo" :src="require('/images/logo.png').default" alt=""
                /></router-link>
            </div>
            <div>
                <div class="menu__items">
                    <router-link class="menu__items" to="/">{{ $t('home') }}</router-link>
                </div>
            </div>
            <div>
                <div class="menu__items">
                    <router-link class="menu__items" to="/about">{{ $t('about') }}</router-link>
                </div>
            </div>
            <div>
                <div class="menu__items">
                    <router-link class="menu__items" to="/products">{{ $t('products') }}</router-link>
                </div>
            </div>
            <div>
                <div class="menu__items">
                    <router-link class="menu__items" to="/services">{{ $t('services') }}</router-link>
                </div>
            </div>
            <div>
                <div class="menu__items">
                    <router-link class="menu__items" to="/laboratory">{{ $t('laboratory') }}</router-link>
                </div>
            </div>
            <div>
                <div class="menu__items">
                    <router-link class="menu__items" to="/discounts">{{ $t('discounts') }}</router-link>
                </div>
            </div>
            <div>
                <div class="menu__items">
                    <router-link class="menu__items" to="/contacts">{{ $t('contacts') }}</router-link>
                </div>
            </div>
        </div>
        <div ref="menuToggleRightSide" class="menu__right_side">
            <div>
                <div class="menu__items">
                    <div class="login" v-if="!loggedIn">
                        <div>
                            <router-link class="menu__items" to="/login">
                                <img class="mr-2 user-img" :src="require('/images/icons/user.png').default"/>{{ $t('login') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="login" v-if="loggedIn">
                        <div class="menu__items" @click.prevent="logout">
                            <img class="mr-2 user-img" :src="require('/images/icons/user.png').default"/>{{ $t('logout') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="lang">
                <div>
                    <div @click="changeLocale('hy')" class="menu__items"><img class="lang-icon"
                        :src="require('/images/icons/arm.png').default" alt="ARM"/>
                    </div>
                </div>
                <div>
                    <div @click="changeLocale('ru')" class="menu__items">
                        <img class="lang-icon" :src="require('/images/icons/rus.png').default" alt="RU"/>
                    </div>
                </div>
                <div>
                    <div @click="changeLocale('en')" class="menu__items">
                        <img class="lang-icon" :src="require('/images/icons/eng.png').default" alt="ENG"/>
                    </div>
                </div>
            </div>
            <div class="adapt-shop">
                <router-link class="menu__items" to="/cart">
                    <div class="cart_div">
                        <div v-if="this.cartLength > 0" class="count_cart_web">{{ this.cartLength }}</div>
                        <img :src="require('/images/menu-items/shp.png').default" alt="SHOP"/>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"

export default {
    name: "Header",
    computed: {
        ...mapGetters({
            loggedIn: "auth/loggedIn",
            cartLength: "product/cartLength"
        })
    },
    data() {
        return {
            menuTogler: false,
        };
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout',
            getCartProducts: "product/getCartProducts",
            // getCartProductsLength: "product/getCartProductsLength"
        }),
        logout() {
            this.$store.dispatch('auth/logout', this.user)
        },
        openMenu() {
            this.menuTogler = !this.menuTogler;
            if (this.menuTogler) {
                this.$refs.menuToggleLeftSide.style = "top: 60px";
                this.$refs.menuToggleRightSide.style = "top: 500px";
            } else {
                this.$refs.menuToggleLeftSide.style = "top: -500px";
                this.$refs.menuToggleRightSide.style = "top: -500px";
            }
        },
        changeLocale(lang) {
            this.$root.$i18n.locale = lang;
            localStorage.lang = lang;
        },
    },
    mounted() {
        this.getCartProducts();
        if (localStorage.lang) {
            this.$root.$i18n.locale = localStorage.lang;
        }
    },
};
</script>

