import instance from '../../../../axios/axios-instance'

const state = {
    errors: [],
    subtypes: {},
    filteredSubtypes: {}
}

const getters = {
    subtypes(state) {
        return state.subtypes
    },
    filteredSubtypes(state) {
        return state.filteredSubtypes
    }
}

const mutations = {
    setSubtypes(state, payload){
        state.subtypes = payload
    },
    setFilteredSubtypes(state, payload){
        state.filteredSubtypes = payload
    }
}

const actions = {
    getSubtypes(ctx) {
        return new Promise((resolve , reject) => {
            instance.get("/api/v1/subtypes").then((response) => {
                ctx.commit('setSubtypes', response.data.data)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getFilteredSubtypes(ctx, type_id) {
        return new Promise((resolve , reject) => {
            instance.get("/api/v1/subtypes").then((response) => {
                let subtypes = response.data.data;
                let result = subtypes.filter((item) => {
                    return item.type_id == type_id
                })
                ctx.commit('setFilteredSubtypes', result)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
