<template>
    <div class="about_component">
        <div class="about_component_car_image">
            <div class="about_component_car_image-circle overflow-hidden">
                <div class="mb-3">
                    <img style="width: 100%" :src="require('/images/home-page/19199057.jpg').default" alt="">
                </div>
            </div>
        </div>
        <div class="about_component_description">
            <div>
                <div>
                    <h1 class="about_component_description-title"><span>{{$t('about_us_first')}}</span> {{$t('about_us_second')}}</h1>
                    <p class="about_component_description-text">{{
                             $t('about_us_text')
                        }} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../UiComponents/Button";
export default {
    name: 'HomeAbout',
    components: {
        Button
    }

}
</script>
