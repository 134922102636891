import instance from '../../../../axios/axios-instance'
import router from "../../../router";

const state = {
    cart: [],
    cartLength: 0,
    cartSum: 0,
    currentProduct: {},
    products: {},
    discountedProducts: {},
    storeProductErrors: [],
    storeCartErrors: [],
    updateProductErrors: [],
    page: 1,
    discountedPage: 1,
    limit: 10,
    discountedPageLimit: 2,
    totalPages: 0,
    discountedPageTotalPages: 0,
}

const getters = {
    page(state){
        return state.page
    },
    limit(state){
        return state.limit
    },
    totalPages(state){
        return state.totalPages
    },
    discountedPage(state){
        return state.discountedPage
    },
    discountedPageLimit(state){
        return state.discountedPageLimit
    },
    discountedPageTotalPages(state){
        return state.discountedPageTotalPages
    },
    cart(state) {
        return state.cart
    },
    cartLength(state) {
        return state.cartLength
    },
    cartSum(state) {
        return state.cartSum
    },
    currentProduct(state) {
        return state.currentProduct
    },
    products(state) {
        return state.products
    },
    discountedProducts(state) {
        return state.discountedProducts
    },
    storeProductErrors(state) {
        return state.storeProductErrors
    },
    storeCartErrors(state) {
        return state.storeCartErrors
    },
    updateProductErrors(state) {
        return state.updateProductErrors
    },
}

const mutations = {

    pushCart(state, payload) {
        state.cart.push(payload)
    },
    setCartLength(state, length) {
        state.cartLength = length
    },
    setCartSum(state, sum) {
        state.cartSum = sum
    },
    addCartLength(state, value) {
        state.cartLength += value
    },
    setCurrentProduct(state, payload) {
        state.currentProduct = payload
    },
    setProducts(state, payload) {
        state.products = payload
    },
    setCartProducts(state, payload) {
        state.cart = payload
    },
    setDiscountedProducts(state, payload) {
        state.discountedProducts = payload
    },
    setStoreProductErrors(state, payload) {
        state.storeProductErrors = payload
    },
    setStoreCartErrors(state, payload) {
        state.storeCartErrors = payload
    },
    setUpdateProductErrors(state, payload) {
        state.updateProductErrors = payload
    },
    setPage(state, page) {
        state.page = page
    },
    setTotalPages(state, totalPages) {
        state.totalPages = totalPages
    },
    setDiscountedPage(state, page) {
        state.discountedPage = page
    },
    setDiscountedPageTotalPages(state, totalPages) {
        state.discountedPageTotalPages = totalPages
    },
}

const actions = {
    getCartProducts(ctx) {
        return new Promise((resolve, reject) => {
            instance.get("/api/v1/cart").then((response) => {
                ctx.commit("setCartProducts", response.data.data)
                let cartLength = 0;
                let products = response.data.data;
                for (let i = 0; i < products.length; i++) {
                    cartLength += products[i].quantity
                }
                ctx.commit('setCartLength', cartLength);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getCartSum(ctx) {
        return new Promise((resolve, reject) => {
            instance.get("/api/v1/cart").then((response) => {
                let products = response.data.data;
                let cartSum = 0;
                for (let i = 0; i < products.length; i++) {
                    cartSum += products[i].price
                }
                ctx.commit('setCartSum', cartSum);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getProducts(ctx) {
        return new Promise((resolve, reject) => {
            instance.get("/api/v1/products",{
                params: {
                    page: state.page,
                },
            }).then((response) => {
                ctx.commit("setProducts", response.data.data.data)
                ctx.commit('setTotalPages', Math.ceil(response.data.data.total / state.limit))
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getFilteredProducts(ctx, id) {
        return new Promise((resolve, reject) => {
            instance.get("/api/v1/products",{
                params: {
                    subtype_id: id
                },
            }).then((response) => {
                ctx.commit("setProducts", response.data.data.data)
                ctx.commit('setTotalPages', Math.ceil(response.data.data.total / state.limit))
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getDiscountedProducts(ctx) {
        return new Promise((resolve, reject) => {
            instance.get("/api/v1/products",{
                params: {
                    page: state.discountedPage,
                },
            }).then((response) => {
                let products = response.data.data.data;
                let result = products.filter((item) => {
                    return item.new_price !== null
                })
                ctx.commit('setDiscountedPageTotalPages', Math.ceil(response.data.data.total / state.discountedPageLimit))
                ctx.commit("setDiscountedProducts", result)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    storeProduct(ctx, product) {
        return new Promise((resolve, reject) => {
            instance.post("/api/v1/products", product).then((response) => {
                router.push('/admin/products')
                resolve(response)
            }).catch((error) => {
                if (error.response.status === 422) {
                    ctx.commit('setStoreProductErrors', error.response.data.errors)
                    reject(error)
                }
            })
        })
    },
    editProduct(ctx, currentProduct) {

        let product = new FormData();
        product.append("id", currentProduct.id);
        product.append("text_en", currentProduct.text_en);
        product.append("text_ru", currentProduct.text_ru);
        product.append("text_hy", currentProduct.text_hy);
        product.append("description_en", currentProduct.description_en);
        product.append("description_ru", currentProduct.description_ru);
        product.append("description_hy", currentProduct.description_hy);
        product.append("old_price", currentProduct.old_price);
        product.append("new_price", currentProduct.new_price);
        product.append("volume", currentProduct.volume);
        product.append("manufacturer", currentProduct.manufacturer);
        product.append("subtype_id", currentProduct.subtype_id);
        product.append("img", currentProduct.img);
        product.append("_method", "put");

        return new Promise((resolve, reject) => {
            instance.post("/api/v1/products/" + currentProduct.id, product).then((response) => {
                router.push('/admin/products')
                resolve(response)
            }).catch((error) => {
                if (error.response.status === 422) {
                    ctx.commit('setUpdateProductErrors', error.response.data.errors)
                    reject(error)
                }
            })
        })
    },
    currentProduct(ctx, id) {
        return new Promise((resolve, reject) => {
            instance.get('/api/v1/products/' + id)
                .then((response) => {
                    ctx.commit('setCurrentProduct', response.data.data)
                }).catch((error) => {
                reject(error)
            })
        })
    },
    showProduct(ctx, id) {
        return new Promise((resolve, reject) => {
            instance.get('/api/v1/products/' + id).then((response) => {
                router.push('/admin/products/' + id)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    destroyProduct(ctx, id) {
        return new Promise((resolve, reject) => {
            instance.delete('/api/v1/products/' + id).then((response) => {
                let result = state.products.filter((item) => {
                    return item.id != id
                })
                ctx.commit('setProducts', result)
                router.push('/admin/products')
                resolve(response)
            }).catch((error) => {
                console.log(error)
                reject(error)
            })
        })
    },
    addToCart(ctx, product) {
        let formData = new FormData();

        if (product.new_price !== null) {
            formData.append('price', product.new_price)
        } else {
            formData.append('price', product.old_price)
        }

        formData.append('product_id', product.id)

        return new Promise((resolve, reject) => {
            instance.post('/api/v1/cart', formData).then((response) => {
                ctx.commit('addCartLength', 1)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    minusProductQuantity(ctx, product) {
        if (product.quantity > 1) {
            let getPrice = product.price / product.quantity;
            let newQuantity = product.quantity - 1;
            let newPrice = getPrice * newQuantity
            let formData = new FormData();
            formData.append("id", product.id);
            formData.append("quantity", newQuantity);
            formData.append("price", newPrice);
            formData.append("_method", "put");

            return new Promise((resolve, reject) => {
                instance.post("/api/v1/cart/" + formData.get('id'), formData).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        }
    },
    plusProductQuantity(ctx, product) {
        let getPrice = product.price / product.quantity;
        let newQuantity = product.quantity + 1;
        let newPrice = getPrice * newQuantity
        let formData = new FormData();
        formData.append("id", product.id);
        formData.append("quantity", newQuantity);
        formData.append("price", newPrice);
        formData.append("_method", "put");

        return new Promise((resolve, reject) => {
            instance.post("/api/v1/cart/" + formData.get('id'), formData).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    makeOrder(ctx, cart) {
        return new Promise((resolve, reject) => {
            instance.post('/api/v1/carts', cart).then((response) => {
                ctx.commit('setCartProducts', [])
                ctx.commit('setCartSum', 0)
                ctx.commit('setCartLength', 0)
                ctx.commit('setStoreCartErrors', [])
                resolve(response)
            }).catch((error) => {
                if (error.response.status === 422) {
                    ctx.commit('setStoreCartErrors', error.response.data.errors)
                    reject(error)
                }
            })
        })
    },
    destroyProductInCart(ctx, product) {
        return new Promise((resolve, reject) => {
            instance.delete('/api/v1/cart/' + product.id).then((response) => {
                let result = state.cart.filter((item) => {
                    return item.id != product.id
                })
                ctx.commit('setCartProducts', result)
                resolve(response)
            }).catch((error) => {
                console.log(error)
                reject(error)
            })
        })
    }
    ,
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
