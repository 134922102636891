import instance from "../../../axios/axios-instance";
import {destroyCookie, setCookie} from 'nookies';
import router from "../../router";


const state = {
    userDetails: {},
    isLoggedIn: false,
    errors: [],
    registrationErrors: [],
    invalidCredentials: '',
    users: []
}

const actions = {
    registerUser(ctx, new_user) {
        return new Promise((resolve, reject) => {
            instance
                .post("/api/v1/register", {
                    name: new_user.name,
                    lastname: new_user.lastname,
                    email: new_user.email,
                    password: new_user.password
                })
                .then(response => {
                    if (response.data) {

                        document.getElementById('auth_container').classList.remove("right-panel-active");

                        new_user.name = "";
                        new_user.lastname = "";
                        new_user.email = "";
                        new_user.password = "";

                        resolve(response)
                    } else {
                        reject(response)
                    }
                    ctx.commit('setRegistrationErrors', [])
                    ctx.commit('setErrors', [])
                    ctx.commit('setInvalidCredentials', '')

                }).catch((error) => {
                if (error.response.status === 422) {
                    ctx.commit('setRegistrationErrors', error.response.data.errors)
                    ctx.commit('setErrors', [])
                    ctx.commit('setInvalidCredentials', '')
                }
            })
        })
    },
    loginUser(ctx, payload) {
        return new Promise((resolve) => {
            instance
                .post("/api/v1/login", payload)
                .then(response => {
                    if (response.data.access_token) {
                        localStorage.setItem('token', response.data.access_token)
                        setCookie(ctx, "refresh_token", response.data.refresh_token, {
                            maxAge: response.data.expires_in
                        })
                        ctx.commit('setLoggedIn', true)
                        ctx.dispatch('currentUser').then(() => resolve(response))
                        ctx.dispatch('checkAdmin').then(() => resolve(response))
                    }
                    ctx.commit('setRegistrationErrors', [])
                    ctx.commit('setErrors', [])
                    ctx.commit('setInvalidCredentials', '')

                }).catch((error) => {
                if (error.response.data.error) {
                    ctx.commit('setInvalidCredentials', error.response.data.error)
                    ctx.commit('setRegistrationErrors', [])
                    ctx.commit('setErrors', [])
                } else if (error.response.status === 422) {
                    ctx.commit('setErrors', error.response.data.errors)
                    ctx.commit('setRegistrationErrors', [])
                    ctx.commit('setInvalidCredentials', '')
                }
            })
        })
    },
    checkAdmin() {
        return new Promise((resolve => {
            instance.get('/api/v1/me')
                .then((response) => {
                    if (response.data.data.roles[0] === "admin") {
                        localStorage.setItem('isAdmin', true);
                        window.location.replace('/admin/dashboard')
                        resolve(true)
                    } else {
                        window.location.replace('/')
                    }
                })
        }))
    },
    logout(ctx) {
        return new Promise((resolve => {
            instance.post('/api/v1/logout')
                .then((response) => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("isAdmin");
                    ctx.commit('setLoggedIn', false)
                    destroyCookie(null, 'refresh_token')
                    window.location.replace('/login')
                    resolve(true)
                }).catch((error) => {
                console.log(error)
            })
        }))
    },

    setLoggedInState(ctx) {
        return new Promise((resolve => {
            if (localStorage.getItem("token")) {
                ctx.commit('setLoggedIn', true)
                resolve(true)
            } else {
                ctx.commit('setLoggedIn', false)
                resolve(false)
            }
        }))
    },
    forgotPassword(ctx, user) {
        return new Promise((resolve, reject) => {
            instance.post('/api/v1/forgot-password', {
                email: user.email,
            })
                .then((response) => {
                    if (response.data) {
                        router.push('/login')
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch((error) => {
                if (error.response.status === 422) {
                    ctx.commit('setErrors', error.response.data.errors)
                    ctx.commit('setInvalidCredentials', '')
                } else if (error.response.status === 500) {
                    ctx.commit('setInvalidCredentials', error.response.data.error)
                    ctx.commit('setErrors', [])
                }
            })
        })
    },

    resetPassword(ctx, payload) {
        return new Promise(resolve => {
            instance.post('/api/v1/reset-password', payload)
                .then((response) => {
                    router.push('/login')
                    resolve(response);
                }).catch((error) => {
                if (error.response.status === 422) {
                    ctx.commit('setErrors', error.response.data.errors)
                    ctx.commit('setInvalidCredentials', '')
                } else if (error.response.status === 500) {
                    ctx.commit('setInvalidCredentials', error.response.data.error)
                    ctx.commit('setErrors', [])
                }
            })
        })
    },
    currentUser(ctx) {
        return new Promise((resolve, reject) => {
            instance.get('/api/v1/user')
                .then((response) => {
                    ctx.commit('setUserDetails', response.data.data)
                }).catch((error) => {
                reject(error)
            })
        })
    },
    changePassword(ctx, payload) {
        return new Promise((resolve, reject) => {
            instance
                .post('/api/v1/change-password', payload)
                .then((response) => {
                    if (response.data.success) {
                        router.push("/login")
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch((error) => {
                    if (error.response.data.error) {
                        ctx.commit('setInvalidCredentials', error.response.data.error)
                    } else if (error.response.status === 422) {
                        ctx.commit('setErrors', error.response.data.errors)
                    }
                })
        })
    },
    updateDetails(ctx, payload) {
        return new Promise((resolve, reject) => {
            instance
                .post('/api/v1/change-details', payload)
                .then((response) => {
                    if (response.data.success) {
                        router.push("/")
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch((error) => {
                    if (error.response.data.error) {
                        ctx.commit('setInvalidCredentials', error.response.data.error)
                    } else if (error.response.status === 422) {
                        ctx.commit('setErrors', error.response.data.errors)
                    }
                })
        })
    },
    getUsers(ctx) {
        return new Promise((resolve, reject) => {
            instance.get('/api/v1/users').then((response) => {
                ctx.commit('setUsers', response.data.data)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

const mutations = {
    setLoggedIn(state, payload) {
        state.isLoggedIn = payload
    },
    setErrors(state, errors) {
        state.errors = errors
    },
    setRegistrationErrors(state, registrationErrors) {
        state.registrationErrors = registrationErrors
    },
    setInvalidCredentials(state, invalidCredentials) {
        state.invalidCredentials = invalidCredentials
    },
    setUserDetails(state, payload) {
        state.userDetails = payload
    },
    setUsers(state, payload) {
        state.users = payload
    },
}

const getters = {
    loggedIn(state) {
        return state.isLoggedIn
    },
    errors(state) {
        return state.errors
    },
    registrationErrors(state) {
        return state.registrationErrors
    },
    invalidCredentials(state) {
        return state.invalidCredentials
    },
    userDetails(state) {
        return state.userDetails
    },
    users(state) {
        return state.users
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
