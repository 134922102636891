<template>
    <div class="d-flex justify-content-around flex-wrap align-items-center col-12">
        <div class="about__description col-md-5 col-12">
            <div>
                <h1 class="about__description-title-yellow">MIRKA</h1>
                <p class="about__description-text">{{$t('about_mirka_first')}}</p>
                <p class="about__description-text-2">{{$t('about_mirka_second')}}</p>
            </div>
        </div>
        <div class="about__car_image col-md-5 col-12">
            <img style="width: 100%" :src="require('/images/home-page/mirka.png').default"/>
            <h1>https://www.mirka.com/ru/ru/</h1>
        </div>
    </div>
    <div class="about__description_container col-md-10 col-12">
        <div>
            <p class="about__description-text-p col-12">{{$t('about_mirka_third')}}</p>
            <p class="about__description-text-p col-12">{{$t('about_mirka_fourth')}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutMirka",
};
</script>
