<template>
    <tr class="col-12">
        <td class="col-2 admin_td">{{ message.name }}</td>
        <td class="col-3 admin_td">{{ message.email }}</td>
        <td class="col-5 admin_td">{{ message.message }}</td>
        <td class="col-2 admin_td">
            <button class="btn btn-outline-danger main-button" @click="$emit('remove', message.id)">Delete</button>
        </td>
    </tr>
</template>

<script>
export default {
    name: "MessageItem",
    props: {
        message: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped>

</style>
