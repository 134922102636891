<template>
    <div :class="direction">
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 24 24">
            <path d="M16 8v-4l8 8-8 8v-4h-5v-8h5zm-7 0h-2v8h2v-8zm-4.014 0h-1.986v8h1.986v-8zm-3.986 0h-1v8h1v-8z"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Arrow',
    props: {
        direction: String,
    }
}
</script>

<style scoped>

.left {
    width: 100px;
    transform: rotate(180deg);
}

.right {
    width: 100px;
    transform: rotate(0deg);
}

.down {
    width: 100px;
    transform: rotate(90deg);
}

</style>
