import Vuex from "vuex"

import middleware from "./modules/middleware";
import auth from "./modules/auth";
import contact_message from "./modules/contact_message";
import subtype from "./modules/Product/subtype";
import product from "./modules/Product/product";
import cart from "./modules/Product/cart";


export default new Vuex.Store({
    modules: {
        middleware,
        auth,
        contact_message,
        subtype,
        product,
        cart
    }
})
