<template>

    <div class="pb-5">
        <InfoCircle class="mt-5 mb-5"/>
    </div>

</template>

<script>
import InfoCircle from "../components/HomeComponents/InfoCircle";

export default {
    name: "Services",
    components: {InfoCircle}
}
</script>

<style scoped>

</style>
