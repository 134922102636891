<template>
    <div class="col-10 offset-1 mt-3 mb-3">
        <div class="d-flex justify-content-around col-12 flex-wrap">
            <transition-group name="products" mode="easy-out" appear>
                <div class="product__card__admin mt-3 mb-3 ml-3 col-3" v-for="product in discountedProducts"
                     :key="product.id">
                    <div class="product__card-logo-block">
                        <div class="product__card-logo"><img :src="require('/images/logo.png').default"></div>
                    </div>
                    <div class="product_presentation">
                        <div class="product_img">
                            <img :src="product.img">
                        </div>
                    </div>
                    <div class="product__description">
                        <div>
                            <p>{{ product.text_hy }}</p>
                        </div>
                    </div>
                    <div class="product__buttons">
                        <div>
                            <button @click="editProduct(product.id)" class="btn btn-outline-success">Edit</button>
                        </div>
                        <div>
                            <button @click="deleteProduct(product.id)" class="btn btn-outline-danger">Delete</button>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "sales",
    computed: {
        ...mapGetters({
            types: "type/types",
            subtypes: "subtype/subtypes",
            discountedProducts: "product/discountedProducts"
        }),
    },
    methods: {
        ...mapActions({
            getTypes: "type/getTypes",
            getSubtypes: "subtype/getSubtypes",
            getDiscountedProducts: "product/getDiscountedProducts"
        }),
        editProduct(id) {
            this.$store.dispatch('product/showProduct', id)
        },
        deleteProduct(id) {
            if (confirm('are you sure?')) {
                this.$store.dispatch('product/destroyProduct', id)
            }
        }
    },
    mounted() {
        this.getTypes();
        this.getSubtypes();
        this.getDiscountedProducts()
    }
}
</script>

<style scoped>
.products-enter-active,
.products-leave-active {
    transition: all 0.4s ease;
}

.products-enter-from,
.products-leave-to {
    opacity: 0;
    transform: translateX(50px);
}

.products-move {
    transition: transform 0.4s ease;
}
</style>
