
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import {createApp} from "vue";
import router from './router';
import App from "./views/App";
import BootstrapVue3 from 'bootstrap-vue-3';
import i18n from './locales/i18n';
import store from "./store"

createApp({
    components: {
        App
    }
}).use(router).use(BootstrapVue3).use(i18n).use(store).mount('#app');
