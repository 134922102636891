<template>
    <div class="col-12">
        <CatalogUi/>
    </div>
    <div class="container mt-5 mb-5">
        <div class='d-flex justify-content-around flex-wrap'>
            <transition-group name="list" mode="easy-in" appear>
                <ProductCard
                    :product="product"
                    v-for="product in products"
                    :key="product.id"
                >
                </ProductCard>
            </transition-group>
        </div>
        <div class="page__wrapper">
            <div
                v-for="pageNumber in totalPages"
                :key="pageNumber"
                class="__page ml-3"
                :class="{
              'current-page': page === pageNumber
            }"
                @click="changePage(pageNumber)"
            >
                {{ pageNumber }}
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ProductCard from "../components/UiComponents/ProductCard";
import CatalogUi from "../components/UiComponents/CatalogUi";

export default {
    name: "productsPage",
    components: {CatalogUi, ProductCard},
    computed: {
        ...mapState({
            page: state => state.product.page,
            limit: state => state.product.limit,
            totalPages: state => state.product.totalPages,
        }),
        ...mapGetters({
            products: "product/products",
            page: "product/page",
        })
    },
    methods: {
        ...mapActions({
            getProducts: "product/getProducts"
        }),
        changePage(pageNumber) {
            this.$store.commit('product/setPage', pageNumber)
        }
    },
    mounted() {
        this.getProducts()
    },
    watch: {
        page() {
            this.getProducts();
        }
    }
}
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}

</style>
