<template>
    <div class="container mt-3">
        <table class="table table-hover admin_table">
            <thead>
            <tr>
                <th class="admin_th" scope="col">Գնորդ</th>
                <th class="admin_th" scope="col">Նկար</th>
                <th class="admin_th" scope="col">Պատվեր</th>
                <th class="admin_th" scope="col">Քանակ</th>
                <th class="admin_th" scope="col">Ընդհանուր արժեք</th>
            </tr>
            <tr>
                <th class="admin_th" scope="col">Քաղաքը</th>
                <th class="admin_th" scope="col">Հասցեն</th>
                <th class="admin_th" scope="col">Մեկնաբանություն</th>
                <th class="admin_th" scope="col">Հաստատել</th>
                <th class="admin_th" scope="col">Հեռացնել</th>
            </tr>
            </thead>
            <transition-group name="order" mode="out-in" appear>
                <CartItem
                    v-for="order in cart"
                    :order="order"
                    :key="order.id"
                    @removeOrder="removeOrder"
                    @completeOrder="completeOrder">
                </CartItem>
            </transition-group>
        </table>
        <!--        <div class="page__wrapper">-->
        <!--            <div-->
        <!--                v-for="pageNumber in totalPages"-->
        <!--                :key="pageNumber"-->
        <!--                class="__page ml-3"-->
        <!--                :class="{-->
        <!--              'current-page': page === pageNumber-->
        <!--            }"-->
        <!--                @click="changePage(pageNumber)"-->
        <!--            >-->
        <!--                {{ pageNumber }}-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CartItem from "./CartItem";

export default {
    name: "AdminCart",
    components: {CartItem},
    computed: {
        ...mapGetters({
            cart: "cart/adminCart"
        })
    },
    methods: {
        ...mapActions({
            getAdminCart: "cart/getAdminCart"
        }),
        removeOrder(id) {
            if (confirm('Հաստատել պարվերը?')) {
                this.$store.dispatch('cart/DeleteOrder', id)
            }
        },
        completeOrder(id) {
            this.$store.dispatch('cart/CompleteOrder', id)
        },
    },
    mounted() {
        this.getAdminCart()
    },
}
</script>

<style scoped>
.order-item {
    display: inline-block;
    margin-right: 10px;
}

.order-enter-active,
.order-leave-active {
    transition: all 0.4s ease;
}

.order-enter-from,
.order-leave-to {
    opacity: 0;
    transform: translateX(50px);
}

.order-move {
    transition: transform 0.4s ease;
}

.page__wrapper {
    display: flex;
    margin-top: 15px;
}

.__page {
    border: 1px solid black;
    padding: 10px;
    user-select: none;
    cursor: pointer;
}

.current-page {
    border: 2px solid teal !important;
    color: teal !important;
}
</style>
