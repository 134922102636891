import instance from '../../../../axios/axios-instance'
import router from "../../../router";

const state = {
    adminCart: []
}

const getters = {
    adminCart(state) {
        return state.adminCart
    }
}

const mutations = {
    setAdminCart(state, payload) {
        state.adminCart = payload
    }
}

const actions = {
    getAdminCart(ctx) {
        return new Promise((resolve, reject) => {
            instance.get('/api/v1/carts').then((response) => {
                ctx.commit('setAdminCart', response.data.data)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    DeleteOrder(ctx, id) {
        return new Promise((resolve, reject) => {
            instance.delete('/api/v1/carts/' + id)
                .then((response) => {
                    let result = state.adminCart.filter((item) => {
                        return item.id !== id
                    })
                    ctx.commit('setAdminCart', result)
                    resolve(response)
                }).catch((error) => {
                reject(error)
            });
        });
    },
    CompleteOrder(ctx, id) {
        return new Promise((resolve, reject) => {
            instance.patch('/api/v1/carts/' + id)
                .then((response) => {
                    ctx.commit('setAdminCart', response.data.data)
                    resolve(response)
                }).catch((error) => {
                reject(error)
            });
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
