import can from "../../helpers/can";
import store from "../store";

export default function checkRoles({next, to, from}) {
    const requireRoles = to.meta.roles

    if (!from.name) {
        store.dispatch('auth/currentUser').then(() => {
                const enter = can(requireRoles)
                if (enter) {
                    return next('/')
                }
                return next()
            }
        )
    }else{
        const enter = can(requireRoles)
        if (enter) {
            return next()
        }
        return next('/')
    }
}
