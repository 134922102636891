<template>
    <b-card class="mt-3 mb-3 col-8 offset-2">
        <ValidationErrors v-if="updateProductErrors" :errors="updateProductErrors"></ValidationErrors>
        <b-form @submit.prevent="updateProduct" enctype="multipart/form-data">
            <div class="mt-3">
                <b-col sm="3">
                    <label for="text_en">Ապրանքի անունը (Eng)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="text_en"
                        v-model="currentProduct.text_en"
                        placeholder="Պարտադիր է լրացնել"
                        trim>
                    </b-form-input>
                    <b-form-text id="text_en-help-block">
                        Ապրանքի անունը (Eng)-ի ժամանակ
                    </b-form-text>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="text_ru">Ապրանքի անունը (Rus)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="text_ru"
                        v-model="currentProduct.text_ru"
                        :state="null"
                        placeholder="Պարտադիր է լրացնել"
                        aria-describedby="text_ru-help text_ru-feedback"
                        trim>
                    </b-form-input>
                    <b-form-text id="text_ru-help-block">
                        Ապրանքի անունը (Rus)-ի ժամանակ
                    </b-form-text>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="text_hy">Ապրանքի անունը (Hay)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="text_hy"
                        v-model="currentProduct.text_hy"
                        :state="null"
                        placeholder="Պարտադիր է լրացնել"
                        aria-describedby="text_hy-help text_hy-feedback"
                        trim>
                    </b-form-input>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="description_en">Ապրանքի նկարագրությունը (Eng)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="description_en"
                        v-model="currentProduct.description_en"
                        placeholder="Պարտադիր է լրացնել"
                        trim>
                    </b-form-input>
                    <b-form-text id="description_en-help-block">
                        Ապրանքի նկարագրությունը (Eng)-ի ժամանակ
                    </b-form-text>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="description_ru">Ապրանքի նկարագրությունը (Rus)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="description_ru"
                        v-model="currentProduct.description_ru"
                        :state="null"
                        placeholder="Պարտադիր է լրացնել"
                        aria-describedby="description_ru-help description_ru-feedback"
                        trim>
                    </b-form-input>
                    <b-form-text id="description_ru-help-block">
                        Ապրանքի նկարագրությունը (Rus)-ի ժամանակ
                    </b-form-text>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="description_hy">Ապրանքի նկարագրությունը (Hay)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="description_hy"
                        v-model="currentProduct.description_hy"
                        :state="null"
                        placeholder="Պարտադիր է լրացնել"
                        aria-describedby="description_hy-help description_hy-feedback"
                        trim>
                    </b-form-input>
                    <b-form-text id="description_hy-help-block">
                        Ապրանքի նկարագրությունը (Hay)-ի ժամանակ
                    </b-form-text>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="old_price">Ապրանքի արժեքը (Руб)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="old_price"
                        type="number"
                        min="0"
                        v-model="currentProduct.old_price"
                        :state="null"
                        placeholder="Պարտադիր է լրացնել"
                        aria-describedby="old_price-help old_price-feedback"
                        trim>
                    </b-form-input>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="new_price">Ապրանքի զեղչված արժեքը (Руб)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        type="number"
                        min="0"
                        id="new_price"
                        v-model="currentProduct.new_price"
                        :state="null"
                        placeholder="Պարտադիր չէ լրացնել"
                        aria-describedby="new_price-help new_price-feedback"
                        trim>
                    </b-form-input>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="volume">Ապրանքի ծավալը (կգ)</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        type="number"
                        min="0"
                        step="0.00000001"
                        id="volume"
                        v-model="currentProduct.volume"
                        :state="null"
                        placeholder="Պարտադիր է լրացնել"
                        aria-describedby="volume-help volume-feedback"
                        trim>
                    </b-form-input>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="manufacturer">Արտադրություն</label>
                </b-col>
                <b-col sm="12">
                    <b-form-input
                        id="manufacturer"
                        v-model="currentProduct.manufacturer"
                        :state="null"
                        placeholder="Պարտադիր է լրացնել"
                        aria-describedby="manufacturer-help manufacturer-feedback"
                        trim>
                    </b-form-input>
                </b-col>
            </div>
            <div class="mt-3" ref="allSubtypes">
                <b-col sm="3">
                    <label for="subtype_id">Ապրանքի ենթատիպը</label>
                </b-col>
                <b-col sm="12">
                    <b-form-select v-model="currentProduct.subtype_id" id="subtype_id" class="mb-3">
                        <template #first>
                            <b-form-select-option :value="null" disabled>Ընտրեք ենթատիպը</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="subtype in subtypes" :key="subtype.id" :value="subtype.id">
                            {{ subtype.subtype_hy }}
                        </b-form-select-option>
                    </b-form-select>
                </b-col>
            </div>
            <div class="mt-3 d-none" ref="selectSubtypes">
                <b-col sm="3">
                    <label for="subtype_id">Ապրանքի ենթատիպը</label>
                </b-col>
                <b-col sm="12">
                    <b-form-select v-model="currentProduct.subtypes" id="subtype_id" class="mb-3">
                        <template #first>
                            <b-form-select-option :value="null" disabled>Ընտրեք ենթատիպը</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="subtype in filteredSubtypes" :key="subtype.id" :value="subtype.id">
                            {{ subtype.subtype_hy }}
                        </b-form-select-option>
                    </b-form-select>
                </b-col>
            </div>
            <div class="mt-3">
                <b-col sm="3">
                    <label for="img">Ապրանքի նկարը</label>
                </b-col>
                <b-col sm="12">
                    <input type="file" id="img" @change="handleImageSelected"/>
                </b-col>
            </div>
            <div class="mt-3 mb-5 d-flex col-9 justify-content-end">
                <b-button type="submit" variant="outline-success">Հաստատել</b-button>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import ValidationErrors from "../../../components/UiComponents/ValidationErrors";

export default {
    name: "editProduct",
    components: {ValidationErrors},
    computed: {
        ...mapGetters({
            subtypes: "subtype/subtypes",
            filteredSubtypes: "subtype/filteredSubtypes",
            updateProductErrors: 'product/updateProductErrors',
            currentProduct: 'product/currentProduct',
        }),
    },
    methods: {
        ...mapActions({
            getSubtypes: "subtype/getSubtypes",
            getFilteredSubtypes: "subtype/getFilteredSubtypes",
            getCurrentProduct: "product/currentProduct"
        }),
        updateProduct() {
            this.$store.dispatch('product/editProduct', this.currentProduct)
        },
        handleImageSelected(event) {
            if (event.target.files.length !== 0) {
                this.currentProduct.img = event.target.files[0]
            }
        },
        getFilter(event) {
            this.getFilteredSubtypes(event)
            this.$refs.selectSubtypes.classList.remove('d-none')
            this.$refs.allSubtypes.classList.add('d-none')
        },
    },
    mounted() {
        this.getSubtypes()
        this.getFilteredSubtypes()
        this.getCurrentProduct(this.$route.params.id)
    },
}
</script>

<style scoped>

</style>
