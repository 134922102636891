<template>
    <div>
        <b-button class="w-100" @click="filter(subtype.id)">{{ getSubtypeText }} ({{
                subtype.products_length
            }})
        </b-button>
    </div>
</template>

<script>
export default {
    name: "Subtype",
    props: ['subtype'],
    data() {
        return {
            'current_language': this.$root.$i18n.locale
        }
    },
    computed: {
        getSubtypeText() {
            return this.subtype['subtype_' + this.current_language] ?? this.subtype.subtype_ru
        },
    },
    methods: {
        filter(id) {
            this.$emit('filter', id)
        },
    },
    watch: {
        '$root.$i18n.locale': {
            handler(lang) {
                this.current_language = lang;
            },
        }
    }
}
</script>

<style scoped>

</style>
