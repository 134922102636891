const state = {
    user: {
        loggedIn: true,
        token: localStorage.getItem('token') || ''
    },
    admin: {
        isAdmin: localStorage.getItem('isAdmin') || false
    }
}

const actions = {

}

const mutations = {

}

const getters = {
    auth(state) {
        return state.user
    },
    admin(state) {
        return state.admin
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
