<template>
    <div class="d-flex justify-content-around flex-wrap align-items-center col-12">
        <div class="about__description col-md-5 col-12">
            <div>
                <h1 class="about__description-title-red">SCC</h1>
                <p class="about__description-text">{{$t('about_scc')}}</p>
            </div>
        </div>
        <div class="about__car_image col-md-5 col-10">
            <img style="width: 100%" :src="require('/images/home-page/scc.png').default" alt="" />
            <h1>http://www.sccpaint.com/</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutScc",
};
</script>
