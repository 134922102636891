<template>
    <CarColor/>
    <PartnersPresentation/>
    <Lambo />
    <HomeAbout/>
    <HomeSwiper/>
    <InfoCircle/>
    <Laboratory/>
    <StockComponent/>
</template>

<script>
import HomeAbout from "../components/HomeComponents/HomeAbout";
import CarColor from "../components/HomeComponents/CarColor";
import PartnersPresentation from "../components/HomeComponents/PartnersPresentation";
import HomeSwiper from "../components/HomeComponents/HomeSwiper";
import InfoCircle from "../components/HomeComponents/InfoCircle";
import Laboratory from "../components/HomeComponents/Laboratory";
import StockComponent from "../components/HomeComponents/StockComponent";
import Catalog from "../components/HomeComponents/Catalog";
import { mapGetters, mapActions } from 'vuex'
import Lambo from "../components/HomeComponents/Lambo";

export default {
    name: 'home',
    components: {
        Lambo, Catalog, StockComponent, Laboratory, InfoCircle, HomeSwiper, PartnersPresentation, CarColor, HomeAbout,
    },
}
</script>

