<template>
    <div class="product__card">
        <div class="product__card-logo-block">
            <div class="product__card-logo">
                <img :src="require('/images/logo.png').default" alt="logo">
            </div>
        </div>
        <div class="product_presentation">
            <router-link class="product_img" :to="{ name: 'single', params: { id: product.id }}">
                <img :src="product.img" alt="image">
            </router-link>
        </div>
        <div class="product__description">
            <div>
                <router-link style="text-decoration: none;" :to="{ name: 'single', params: { id: product.id }}">
                    <p>{{ getProductText }}</p>
                </router-link>
            </div>
        </div>

        <div class="product__buttons" v-if="product.new_price != null">
            <div>
                <button class="old-price">{{ product.old_price }} ֏</button>
            </div>
            <div>
                <button class="new-price">{{ product.new_price }} ֏</button>
            </div>
        </div>

        <div class="product__buttons" v-else>
            <div>
                <button class="new-price">{{ product.old_price }} ֏</button>
            </div>
        </div>

        <div class="product__buttons">
            <div>
                <button class="product__add" @click="addCart(product)">
                    <img :src="require('/images/menu-items/shp.png').default" class="mr-2" alt="shp">{{ $t('add_to_cart') }}
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'ProductCard',
    props: ['product'],
    data() {
        return {
            'current_language': this.$root.$i18n.locale
        }
    },
    computed: {
        getProductText() {
            return this.product['text_' + this.current_language] ?? this.product.text_ru
        },
        ...mapGetters({
            cart: 'product/cart',
            logged: 'auth/loggedIn'
        })
    },
    methods: {
        ...mapActions({
            addToCart: 'product/addToCart'
        }),
        addCart(product) {
            if (this.logged !== false) {
                this.addToCart(product)
            } else {
                this.$router.replace('/login');
            }
        }
    },
    watch: {
        '$root.$i18n.locale': {
            handler(lang) {
                this.current_language = lang;
            },
        }
    }
}
</script>

<style scoped>
</style>
