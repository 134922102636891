<template>
    <div class="catalogs">
        <div class='d-flex justify-content-around flex-wrap col-12'>
            <Subtype
                class="catalog_type d-flex justify-content-between align-items-center col-lg-4 col-md-4 col-12"
                :subtype="subtype"
                v-for="subtype in subtypes"
                :key="subtype.id"
                @filter="filter"
            />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Subtype from "./Subtype";

export default {
    components: {Subtype},
    name: "CatalogUi",

    computed: {
        ...mapGetters({
            subtypes: "subtype/subtypes",
        }),
    },
    methods: {
        ...mapActions({
            getFilteredProducts: "product/getFilteredProducts"
        }),
        filter(id) {
            this.getFilteredProducts(id);
        },
        ...mapActions({
            getSubtypes: "subtype/getSubtypes"
        }),
    },
    mounted() {
        this.getSubtypes();
    },
}
</script>

