import {ref} from 'vue';
import axios from "axios";
import {useRouter} from 'vue-router';

export default function useSubtypes() {

    const subtypes = ref([]);
    const subtype = ref([]);
    const router = useRouter();
    const errors = ref('');

    const getSubtypes = async () => {
        let response = await axios.get('/api/v1/subtypes')
        subtypes.value = response.data.data;
    }

    const getSubtype = async (id) => {
        let response = await axios.get('/api/v1/subtypes/' + id)
        subtype.value = response.data.data;
    }

    const storeSubtype = async (data) => {
        errors.value = '';
        try {
            await axios.post('/api/v1/subtypes', data)
            await router.push({name: 'home'})
        } catch (e) {
            if (e.response.status === 422) {
                for (const key in e.response.data.errors) {
                    errors.value += e.response.data.errors[key][0] + '';
                }
            }
        }

    }

    const updateSubtype = async (id) => {
        errors.value = '';
        try {
            await axios.put('/api/v1/subtypes/' + id, subtype.value)
            await router.push({name: 'home'})
        } catch (e) {
            if (e.response.status === 422) {
                for (const key in e.response.data.errors) {
                    errors.value += e.response.data.errors[key][0] + '';
                }
            }
        }
    }

    const destroySubtype = async (id) => {
        await axios.delete('/api/v1/subtypes/' + id)
    }

    return {
        subtype,
        subtypes,
        errors,
        getSubtype,
        getSubtypes,
        storeSubtype,
        updateSubtype,
        destroySubtype,
    }
}
