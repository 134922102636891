<template>
    <div class="container mt-3 d-flex justify-content-center col-12">
        <div class="d-flex justify-content-center flex-column col-md-8 col-12">
            <div class="error-alert" v-if="invalidCredentials"><i class="fa fa-times-circle"></i>
                {{ $t('invalid_credentials') }}
            </div>
            <ValidationErrors v-if="validationErrors" :errors="validationErrors"></ValidationErrors>
        </div>
    </div>
    <div class="auth_container">
        <div class="container" id="auth_container">
            <div class="form-container sign-in-container">
                <form action="#">
                    <h1>{{ $t('enter_email') }}</h1>
                    <input type="text" :placeholder="$t('email')" v-model="user.email"/>
                    <button type="submit" @click.prevent="sendForgotPassword" class="buttonchik"
                            style="text-transform: none">{{ $t('send_email') }}
                    </button>
                </form>
            </div>
            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-right">
                        <h1>{{ $t('welcome') }}</h1>
                        <p>
                            {{ $t('partners_presentation_text') }}
                        </p>
                        <router-link class="buttonchik ghost text-decoration-none" to="/login">{{
                                $t('login')
                            }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ValidationErrors from "../../components/UiComponents/ValidationErrors";

export default {
    name: "ForgotPassword",
    components: {ValidationErrors},
    data: () => ({
        user: {
            email: "",
        }
    }),
    computed: {
        ...mapGetters({
            invalidCredentials: 'auth/invalidCredentials',
            validationErrors: 'auth/errors',
        })
    },
    created() {
        this.checkUserState();
    },
    methods: {
        ...mapActions({
            checkUserState: 'auth/setLoggedInState',
            forgotPassword: 'auth/forgotPassword',
        }),
        sendForgotPassword() {
            this.$store.dispatch('auth/forgotPassword', this.user);
        },
    },
}
</script>

