<template>
    <Laboratory class="mb-5" />
</template>

<script>
import Laboratory from "../components/HomeComponents/Laboratory";

export default {
    name: "laboratoryPage",
    components: {Laboratory}
}

</script>

<style scoped>

</style>
