<template>
    <div class="partner__block">
        <div class="block__present">
            <div class="partners__title">
                <p>{{ $t('partners_presentation_text') }}</p>
            </div>
            <div class="partners__logo_present">
                <div><img :src="require('/images/home-page/scc.png').default" alt=""></div>
                <div><img :src="require('/images/home-page/mirka.png').default" alt=""></div>
                <div><img :src="require('/images/home-page/roberlo.png').default" alt=""></div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'PartnersPresentation'
}
</script>

