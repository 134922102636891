<template>
    <div class="container mt-3 d-flex justify-content-center col-12">
        <div class="d-flex justify-content-center flex-column col-md-8 col-12">
            <div class="error-alert" v-if="invalidCredentials"><i class="fa fa-times-circle"></i> {{ $t('invalid_credentials') }}</div>
            <ValidationErrors v-if="validationErrors" :errors="validationErrors"></ValidationErrors>
            <RegistrationErrors v-if="registrationErrors" :registrationErrors="registrationErrors"></RegistrationErrors>
        </div>
    </div>
    <div class="auth_container">
        <div class="container" id="auth_container" ref="auth_container">
            <div class="form-container sign-up-container">
                <form action="#">
                    <h1>{{ $t('create_account') }}</h1>
                    <input type="text" :placeholder="$t('name')" v-model="new_user.name"/>
                    <input type="text" :placeholder="$t('lastname')" v-model="new_user.lastname"/>
                    <input type="email" :placeholder="$t('email')" v-model="new_user.email"/>
                    <input type="password" :placeholder="$t('password')" v-model="new_user.password"/>
                    <button type="submit" @click.prevent="register" class="buttonchik">{{ $t('registration') }}</button>
                </form>
            </div>
            <div class="form-container sign-in-container">
                <form action="#">
                    <h1>{{ $t('login') }}</h1>
                    <input type="email" :placeholder="$t('email')" v-model="user.email"/>
                    <input type="password" :placeholder="$t('password')" v-model="user.password"/>
                    <router-link style="text-transform: none" to="/forgot-password">{{
                            $t('forgot_your_password')
                        }}
                    </router-link>
                    <button type="submit" @click.prevent="login" class="buttonchik">{{ $t('login') }}</button>
                </form>
            </div>
            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-left">
                        <h1>{{ $t('welcome') }}</h1>
                        <p>
                            {{ $t('partners_presentation_text') }}
                        </p>
                        <button class="buttonchik ghost" @click="signInButton">{{ $t('login') }}</button>
                    </div>
                    <div class="overlay-panel overlay-right">
                        <h1>{{ $t('welcome') }}</h1>
                        <p>
                            {{ $t('partners_presentation_text') }}
                        </p>
                        <button class="buttonchik ghost" @click="signUpButton">{{ $t('registration') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ValidationErrors from "../components/UiComponents/ValidationErrors";
import RegistrationErrors from "../components/UiComponents/RegistrationErrors";

export default {
    name: "Login",
    components: {RegistrationErrors, ValidationErrors},
    data: () => ({
        user: {
            email: "",
            password: ""
        },
        new_user: {
            name: "",
            lastname: "",
            email: "",
            password: ""
        },
    }),
    created() {
        this.checkUserState()
    },
    computed: {
        ...mapGetters({
            invalidCredentials: 'auth/invalidCredentials',
            validationErrors: 'auth/errors',
            registrationErrors: 'auth/registrationErrors'
        })
    },
    methods: {
        ...mapActions({
            checkUserState: 'auth/setLoggedInState',
        }),
        register() {
            this.$store.dispatch('auth/registerUser', this.new_user);
        },
        login() {
            this.$store.dispatch('auth/loginUser', this.user)
        },
        // checkUserState() {
        //     this.$store.dispatch('auth/setLoggedInState', this.user)
        // }
        signUpButton() {
            this.$refs.auth_container.classList.add("right-panel-active");
        },
        signInButton() {
            this.$refs.auth_container.classList.remove("right-panel-active");
        }
    },
};
</script>

