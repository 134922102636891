<template>
    <div class="container mt-3">
        <table class="table table-hover admin_table">
            <thead>
            <tr>
                <th class="admin_th" scope="col">Անուն</th>
                <th class="admin_th" scope="col">Էլ․ հասցեն</th>
                <th class="admin_th" scope="col">Հաղորդագրություն</th>
                <th class="admin_th" scope="col">Հեռացնել</th>
            </tr>
            </thead>
            <tbody>
            <transition-group name="user-list" mode="easy-out" appear>
                <MessageItem
                    v-for="message in messages"
                    :message="message"
                    :key="message.id"
                    @remove="removeMessage">
                </MessageItem>
            </transition-group>
            </tbody>
        </table>
        <div class="page__wrapper">
            <div
                v-for="pageNumber in totalPages"
                :key="pageNumber"
                class="__page ml-3"
                :class="{
              'current-page': page === pageNumber
            }"
                @click="changePage(pageNumber)"
            >
                {{ pageNumber }}
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import MessageItem from "./MessageItem";

export default {
    name: 'Messages',
    components: {MessageItem},
    computed: {
        ...mapState({
            page: state => state.contact_message.page,
            limit: state => state.contact_message.limit,
            totalPages: state => state.contact_message.totalPages,
        }),
        ...mapGetters({
            messages: "contact_message/messages",
            page: "contact_message/page"
        })
    },
    methods: {
        ...mapActions({
            getMessages: 'contact_message/getMessages',
        }),
        removeMessage(id) {
            this.$store.dispatch('contact_message/DeleteMessage', id)
        },
        changePage(pageNumber){
            this.$store.commit('contact_message/setPage', pageNumber)
        }
    },
    mounted() {
        this.getMessages();
    },
    watch:{
        page(){
            this.getMessages();
        }
    }
}
</script>

<style scoped>
.user-list-item {
    display: inline-block;
    margin-right: 10px;
}

.user-list-enter-active,
.user-list-leave-active {
    transition: all 0.4s ease;
}

.user-list-enter-from,
.user-list-leave-to {
    opacity: 0;
    transform: translateX(50px);
}

.user-list-move {
    transition: transform 0.4s ease;
}
.page__wrapper {
    display: flex;
    margin-top: 15px;
}
.__page {
    border: 1px solid black;
    padding: 10px;
    user-select: none;
    cursor: pointer;
}
.current-page {
    border: 2px solid teal;
}
</style>
=
