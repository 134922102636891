<template>
    <tbody>
    <tr class="col-6" v-for="eachOrder in order.cart" :key="eachOrder.id">
        <td class="col-2 admin_td" v-for="userInfo in order.user" :key="userInfo.id">
            {{ userInfo.name + " " + userInfo.lastname }}
        </td>
        <td class="col-3 admin_td">
            <a target="_blank" :href="eachOrder.img">
                <img alt="Order" :src=eachOrder.img>
            </a>
        </td>
        <td class="col-3 admin_td">{{ eachOrder.text_ru }}</td>
        <td class="col-2 admin_td">{{ eachOrder.quantity }}</td>
        <td class="col-2 admin_td">{{ eachOrder.price }} դրամ</td>
    </tr>
    <tr class="col-6">
        <td class="col-2 admin_td">{{ order.city }}</td>
        <td class="col-2 admin_td">{{ order.address }}</td>
        <td class="col-2 admin_td">{{ order.message }}</td>
        <td class="col-2 admin_td">
            <button v-if="order.completed" class="btn btn-success main-button"
                    @click="$emit('completeOrder', order.id)">Հաստատված</button>
            <button v-else class="btn btn-outline-success main-button"
                    @click="$emit('completeOrder', order.id)">Հաստատել</button>
        </td>
        <td class="col-2 admin_td">
            <button class="btn btn-outline-danger main-button" @click="$emit('removeOrder', order.id)">Հեռացնել</button>
        </td>
    </tr>
    </tbody>
</template>

<script>
export default {
    name: "CartItem",
    inheritAttrs: false,
    props: {
        order: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped>

</style>
