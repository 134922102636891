<template>
    <div class="container mt-3">
        <table class="table table-hover">
            <thead>
            <tr>
                <th class="admin_th" scope="col">Անուն</th>
                <th class="admin_th" scope="col">Ազգանուն</th>
                <th class="admin_th" scope="col">Էլ․ հասցեն</th>
            </tr>
            </thead>
            <tbody>
            <transition-group name="user" mode="easy-out" appear>
                <tr v-for="(user,index) in users" :key="index">
                    <td class="admin_td">{{ user.name }}</td>
                    <td class="admin_td">{{ user.lastname }}</td>
                    <td class="admin_td">{{ user.email }}</td>
                </tr>
            </transition-group>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Dashboard",
    computed: {
        ...mapGetters({
            users: "auth/users",
        }),
    },
    methods: {
        ...mapActions({
            getUsers: 'auth/getUsers',
        }),
    },
    mounted() {
        this.getUsers();
    },
}
</script>

<style scoped>
.user-item {
    display: inline-block;
    margin-right: 10px;
}

.user-enter-active,
.user-leave-active {
    transition: all 0.4s ease;
}

.user-enter-from,
.user-leave-to {
    opacity: 0;
    transform: translateX(50px);
}

.user-move {
    transition: transform 0.4s ease;
}
</style>
