<template>
    <ContactUs />
    <ContactsMap />
</template>

<script>
import ContactUs from "../components/ContactsComponents/ContactsUs";
import ContactsMap from "../components/ContactsComponents/ContactsMap";
export default {
    name: 'Contacts',
    components: {
        ContactUs,
        ContactsMap,
    }
}
</script>
