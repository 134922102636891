<template>
    <div class="stock__block">
        <div class="stock__title">
            <p>{{ $t('discounts') }}</p>
        </div>
        <div class="home-swiper">
            <Swiper :slides-per-view="this.sliderCount">
                <SwiperSlide class="d-flex justify-content-around" v-for="product of discountedProducts" :key="product.id">
                    <product-card :product="product"/>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProductCard from "../UiComponents/ProductCard";
import {Swiper, SwiperSlide} from "swiper/vue";

export default {
    name: 'StockComponent',
    components: {ProductCard,Swiper,SwiperSlide},
    data() {
        return {
            sliderCount: "3",
        }
    },
    computed: {
        ...mapGetters({
            discountedProducts: "product/discountedProducts"
        }),
    },
    methods: {
        ...mapActions({
            getDiscountedProducts: "product/getDiscountedProducts"
        }),
        mySliderSizeHandler(e) {
            if (e.target.innerWidth < 1350) {
                this.sliderCount = "2";
            }
            if (e.target.innerWidth < 992) {
                this.sliderCount = "1";
            }
        }
    },
    mounted() {
        this.getDiscountedProducts();
        window.addEventListener("DOMContentLoaded", this.mySliderSizeHandler);
        window.addEventListener("resize", this.mySliderSizeHandler);
    },
}
</script>

