<template xmlns="">
    <div class="container mt-3">
        <div class="error-alert" v-if="invalidCredentials"><p>Invalid Credentials</p></div>
        <ValidationErrors v-if="validationErrors" :errors="validationErrors"></ValidationErrors>
    </div>
    <div class="auth_container">
        <div class="container" id="auth_container">
            <div class="form-container sign-in-container">
                <form action="#">
                    <h1>{{ $t('restore_password') }}</h1>
                    <input type="text" :placeholder="$t('email')" v-model="user.email"/>
                    <input type="password" :placeholder="$t('password')" v-model="user.password"/>
                    <input type="password" :placeholder="$t('confirm_password')" v-model="user.password_confirmation"/>
                    <button type="submit" @click.prevent="sendResetPassword" class="buttonchik" style="text-transform: none">{{ $t('restore') }}</button>
                </form>
            </div>
            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-right">
                        <h1>{{ $t('welcome') }}</h1>
                        <p>
                            {{ $t('partners_presentation_text') }}
                        </p>
                        <router-link class="buttonchik ghost text-decoration-none"  to="/login">{{ $t('login') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ValidationErrors from "../../components/UiComponents/ValidationErrors";
export default {
    name: "ResetPassword",
    components: {ValidationErrors},
    data: () => ({
        user: {
            email: "",
            password: "",
            password_confirmation: "",
        }
    }),
    computed: {
        ...mapGetters({
            invalidCredentials: 'auth/invalidCredentials',
            validationErrors: 'auth/errors',
        })
    },
    created() {
        this.checkUserState();
    },
    methods: {
        ...mapActions({
            checkUserState: 'auth/setLoggedInState',
            resetPassword: 'auth/resetPassword',
        }),
        sendResetPassword() {
            const token = this.$route.params.token;
            this.resetPassword({...this.user,token})
        },
    },
}
</script>
