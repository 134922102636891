<template>
    <div class="d-flex justify-content-around flex-wrap align-items-center col-12">
        <div class="about__car_image col-md-5 col-12">
            <img style="width: 100%" :src="require('/images/home-page/roberlo.png').default" alt="" />
            <h1>https://en.roberlo.com/</h1>
        </div>
        <div class="about__description col-md-5 col-12">
            <div>
                <h1 class="about__description-title-blue">roberlo</h1>
                <p class="about__description-text">{{$t('about_roberlo')}}</p>
            </div>
        </div>
    </div>
    <div class="about__description_container col-md-10 col-12">
        <div>
            <p class="about__description-text-p col-12">{{$t('about_roberlo_first')}}</p>
            <p class="about__description-text-p col-12">{{$t('about_roberlo_second')}}</p>
            <p class="about__description-text-p col-12">{{$t('about_roberlo_third')}}</p>
            <p class="about__description-text-p col-12">{{$t('about_roberlo_fourth')}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutRoberlo",
};
</script>
