<template>
    <div class="container mt-3">
        <b-alert
            v-model="showTop"
            style="text-align: center"
            variant="success"
            id="showTopId"
            dismissible
        >
            {{ $t('message_success') }}
        </b-alert>
    </div>
    <div class="container mt-3 d-flex justify-content-center col-12">
        <div class="d-flex justify-content-center flex-column col-md-8 col-12">
            <ValidationErrors v-if="validationErrors" :errors="validationErrors"></ValidationErrors>
        </div>
    </div>
    <div class="contact_us_background">
        <div class="contact_us_container">
            <div class="contact_us_screen">
                <div class="contact_us_screen-header">
                    <div class="contact_us_screen-header-left">
                        <div class="contact_us_screen-header-button close_contact"></div>
                        <div class="contact_us_screen-header-button maximize_contact"></div>
                        <div class="contact_us_screen-header-button minimize_contact"></div>
                    </div>
                    <div class="contact_us_screen-header-right">
                        <div class="contact_us_screen-header-ellipsis"></div>
                        <div class="contact_us_screen-header-ellipsis"></div>
                        <div class="contact_us_screen-header-ellipsis"></div>
                    </div>
                </div>
                <div class="contact_us_screen-body">
                    <div class="contact_us_screen-body-item left">
                        <div class="contact_us_app-title">
                            <span>{{ $t('connect_with_us') }}</span>
                        </div>
                        <div class="contact_us_app-contact">
                            <div class="contact_us_footer-center">
                                <div>
                                    <i class="fa-solid fa-location-dot"></i>
                                    <a
                                        target="_black"
                                        href="https://goo.gl/maps/oDprWX14KrPExVcJ8"
                                    ><span>{{ $t('street') }}</span> {{ $t('building') }}</a
                                    >
                                </div>
                                <div>
                                    <i class="fa fa-phone"></i>
                                    <a href="tel:%2B37433460096">+374 91-54-33-03</a>
                                </div>
                                <div>
                                    <i class="fa fa-envelope"></i>
                                    <p>
                                        <a href="mailto:info@carcolors.am">info@carcolors.am</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="contact_us_footer-icons">
                            <a target="_black" href="https://www.facebook.com/carcolors.am/"
                            ><i class="fa-brands fa-facebook"></i
                            ></a>
                            <a target="_black" href="https://wa.me/%2B37491543303"
                            ><i class="fa-brands fa-whatsapp"></i
                            ></a>
                            <a href="tel:%2B37433460096"
                            ><i class="fa-brands fa-viber"></i
                            ></a>
                            <a target="_black" href="https://goo.gl/maps/oDprWX14KrPExVcJ8"
                            ><i class="fa-solid fa-location-dot"></i
                            ></a>
                        </div>
                    </div>
                    <div class="contact_us_screen-body-item">
                        <div class="contact_us_app-title-right">
                            <span>{{ $t('leave_message') }}</span>
                        </div>
                        <div class="contact_us_app-form">
                            <div class="contact_us_app-form-group">
                                <input class="contact_us_app-form-control" :placeholder="$t('name')"
                                       v-model="message.name"/>
                            </div>
                            <div class="contact_us_app-form-group">
                                <input
                                    class="contact_us_app-form-control"
                                    :placeholder="$t('email')"
                                    v-model="message.email"
                                />
                            </div>
                            <div class="contact_us_app-form-group message">
                <textarea
                    class="contact_us_app-form-control"
                    :placeholder="$t('message')"
                    v-model="message.message"
                ></textarea>
                            </div>
                            <div class="contact_us_app-form-group buttons">
                                <button class="contact_us_app-form-button" @click.prevent="sendMessage">{{
                                        $t('send')
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ValidationErrors from "../UiComponents/ValidationErrors";

export default {
    name: "ContactUs",
    components: {ValidationErrors},
    data: () => ({
        message: {
            name: "",
            email: "",
            message: ""
        },
        showTop: false
    }),
    computed: {
        ...mapGetters({
            validationErrors: 'contact_message/errors',
        })
    },
    methods: {
        sendMessage() {
            this.$store.dispatch('contact_message/sendMessage', this.message).then(() => {
                this.showTop = true
            })
        },
    },
};
</script>
