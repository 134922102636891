<template>
    <div class="partners">
        <div>
            <img class="p-0_5" style="border-radius: 20px;" :src="require('/images/home-page/scc.png').default" alt="">
        </div>
        <div>
            <img class="p-0_5" :src="require('/images/home-page/mirka2.jpg').default" alt="">
        </div>
        <div>
            <img class="p-0_5" :src="require('/images/home-page/roberlo.png').default" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'PartnersLogo'
}
</script>

