<template>
    <div class="error-alert" v-for="(value,index) in validationErrorsFunction" :key="index">
        <i class="fa fa-times-circle"></i> {{ value }}
    </div>
</template>
-
<script>
export default {
    name: "ValidationErrors",
    props: ['errors'],
    computed: {
        validationErrorsFunction() {
            let errors = Object.values(this.errors);
            errors = errors.flat()
            for (let i = 0; i < errors.length; i++) {
                if (errors[i] == 'Email is required') {
                    errors[i] = this.$t('email.required')
                }else if (errors[i] == 'City is required') {
                    errors[i] = this.$t('city.required')
                }else if (errors[i] == 'Cart is required') {
                    errors[i] = this.$t('cart.empty')
                }else if (errors[i] == 'Address is required') {
                    errors[i] = this.$t('address.required')
                } else if (errors[i] == 'Email must be a valid email address.') {
                    errors[i] = this.$t('email.email')
                }else if (errors[i] == 'Password is required') {
                    errors[i] = this.$t('password.required')
                }else if(errors[i] == 'Email must be a valid email address.'){
                    errors[i] = this.$t('password.min')
                }else if (errors[i] == 'Name is required') {
                    errors[i] = this.$t('name.required')
                }else if (errors[i] == 'Message is required') {
                    errors[i] = this.$t('message.required')
                }
            }
            return errors;
        }
    }
}
</script>

<style scoped>

</style>
