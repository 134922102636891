<template>
    <div class="container mt-5 mb-5">
        <ValidationErrors v-if="cartErrors" :errors="cartErrors"></ValidationErrors>
    </div>
    <div class="container mt-5 mb-5 d-flex flex-wrap justify-content-around">
        <div class="col-md-3 col-12">
            <label class="fourth-color" for="city">{{ $t('city') }}</label>
            <input v-model="this.city" placeholder="Required city" class="form-control" id="city" type="text">
        </div>
        <div class="col-md-3 col-12">
            <label class="fourth-color" for="address">{{ $t('address') }}</label>
            <input v-model="this.address" placeholder="Required address" class="form-control" type="text"
                   id="address">
        </div>
        <div class="col-md-3 col-12">
            <label class="fourth-color" for="message">{{ $t('comments') }}</label>
            <textarea v-model="this.message" placeholder="Required message" class="form-control"
                      id="message"></textarea>
        </div>
    </div>
    <div class="container">
        <hr class="fourth-color">
    </div>
    <div v-for="product in cart" :key="product.id">
        <div class="col-10 offset-1 d-flex flex-wrap justify-content-around">
            <div class="card-img col-md-3 col-12 d-flex flex-wrap justify-content-center align-items-center">
                <img class="w-50" :src="product.img" alt="">
            </div>
            <div class="card-body col-md-3 col-12 d-flex flex-wrap justify-content-center align-items-center">
                <h5 class="fourth-color">{{ product.text_ru }}</h5>
            </div>
            <div class="card-body col-md-3 col-12 d-flex flex-wrap justify-content-center align-items-center">
                <div class="btns__action">
                    <div style="cursor: pointer" @click="minus(product)">
                        <img :src="require('/images/button-icons/minus.png').default" alt="">
                    </div>
                    <div class="sans-serif">{{ product.quantity }}</div>
                    <div style="cursor: pointer" @click="plus(product)">
                        <img :src="require('/images/button-icons/plus.png').default" alt="">
                    </div>
                </div>
            </div>
            <div class="card-body col-md-3 col-12 d-flex flex-wrap d-flex justify-content-center align-items-center">
                <h5 class="fourth-color sans-serif">{{ product.price }} РУБ</h5>
            </div>
            <div class="card-body col-3 d-flex justify-content-center align-items-center">
                <button class="btn btn-danger" @click="deleteProduct(product)">Удалить</button>
            </div>
        </div>
        <div class="container">
            <hr class="fourth-color">
        </div>
    </div>
    <div class="container col-12 d-flex justify-content-end mt-3 mb-5">
        <div class="col-md-6 col-12 d-flex justify-content-around">
            <label class="form-label fourth-color sans-serif">Сума: {{ this.cartSum }} Руб</label>
            <button class="btn btn-success" @click="makeOrder(cart)">Оформить заказ</button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ValidationErrors from "../components/UiComponents/ValidationErrors";

export default {
    name: "Cart",
    components: {ValidationErrors},
    data: () => ({
        city: "",
        address: "",
        message: "",
    }),
    computed: {
        ...mapGetters({
            cart: 'product/cart',
            cartSum: 'product/cartSum',
            cartErrors: 'product/storeCartErrors'
        }),
    },
    methods: {
        ...mapActions({
            getCartProducts: "product/getCartProducts",
            getCartSum: "product/getCartSum"
        }),
        deleteProduct(product) {
            if (confirm('Հեռացնել ապրանքը?')) {
                this.$store.dispatch('product/destroyProductInCart', product)
            }
            this.getCartProducts()
            this.getCartSum()
        },
        minus(product) {
            this.$store.dispatch('product/minusProductQuantity', product)
            this.getCartProducts()
            this.getCartSum()
        },
        plus(product) {
            this.$store.dispatch('product/plusProductQuantity', product)
            this.getCartProducts()
            this.getCartSum()
        },
        makeOrder(cart) {
            this.$store.dispatch('product/makeOrder', {
                'cart': cart,
                'city': this.city,
                'address': this.address,
                'message': this.message,
            }).then(() => {
                this.city = ''
                this.address = ''
                this.message = ''
                this.getCartProducts()
                this.getCartSum()
            })
        }
    },
    mounted() {
        this.getCartProducts()
        this.getCartSum()
    }
}
</script>

<style scoped>

</style>
