<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import AdminLayout from "../layouts/AdminLayout";

export default {
    name: "App",
    computed: {
        layout() {
            return (this.$route.meta.layout || 'main') + '-layout'
        },
    },
    components: {
        MainLayout,
        AdminLayout
    }
}
</script>
