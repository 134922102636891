<template>
    <About-cars />
    <About-scc />
    <About-roberlo />
    <About-mirka />
</template>

<script>
import AboutCars from "../components/AboutComponents/AboutCars";
import AboutScc from "../components/AboutComponents/AboutScc";
import AboutRoberlo from "../components/AboutComponents/AboutRoberlo";
import AboutMirka from "../components/AboutComponents/AboutMirka";

export default {
    name: 'About',
    components: {
        AboutCars,
        AboutScc,
        AboutRoberlo,
        AboutMirka,
    }
}
</script>
