<template>
    <footer class="footer-distributed">
        <div class="footer-left">
            <h3>Armenian<span>CarColors</span></h3>
            <p class="footer-links">
                <router-link class="item" to="/" exact>{{ $t('home') }}</router-link>
                <router-link class="item" to="/about">{{ $t('about') }}</router-link>
                <router-link class="item" to="/products">{{ $t('products') }}</router-link>
                <router-link class="item" to="/services">{{ $t('services') }}</router-link>
                <router-link class="item" to="/laboratory">{{ $t('laboratory') }}</router-link>
                <router-link class="item" to="/discounts">{{ $t('discounts') }}</router-link>
                <router-link class="item" to="/contacts">{{ $t('contacts') }}</router-link>
            </p>

            <p class="footer-company-name">
                Copyright ©{{new Date().getFullYear()}} All rights reserved | This website is made with <i class="fa fa-heart anim" aria-hidden="true"></i> by <a style="text-decoration:none; color: lightseagreen" href="https://serious.am" target="_blank">Serious</a>
            </p>
        </div>

        <div class="footer-center">
            <div>
                <i class="fa-solid fa-location-dot"></i>
                <a target="_black" href="https://goo.gl/maps/oDprWX14KrPExVcJ8"><span>{{ $t('street') }}</span> {{$t('building')}}</a>
            </div>

            <div>
                <i class="fa fa-phone"></i>
                <a href="tel:%2B37433460096">+374 91-54-33-03</a>
            </div>

            <div>
                <i class="fa fa-envelope"></i>
                <p><a href="mailto:info@carcolors.am">info@carcolors.am</a></p>
            </div>
        </div>

        <div class="footer-right">
            <p class="footer-company-about">
                <span>{{ $t('auto_paints')+" "+$t('shop') }}</span>
                {{
                    $t('partners_presentation_text')
                }}
            </p>
            <div class="footer-icons">
                <a target="_black" href="https://www.facebook.com/carcolors.am/"><i class="fa-brands fa-facebook"></i></a>
                <a target="_black" href="https://wa.me/%2B37491543303"><i class="fa-brands fa-whatsapp"></i></a>
                <a href="tel:%2B37433460096"><i class="fa-brands fa-viber"></i></a>
                <a target="_black" href="https://goo.gl/maps/oDprWX14KrPExVcJ8"><i class="fa-solid fa-location-dot"></i></a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style scoped>
</style>
