import auth from "./auth"
import guest from "./guest"
import admin from "./admin"
import checkRoles from "./checkRoles"

export default {
    auth,
    guest,
    admin,
    checkRoles
}
