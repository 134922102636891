<template>
    <div class="home_swiper">
        <div class="home_swiper-text">
            <span class="third-color">{{ $t('our_to_many') }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span class="fourth-color">{{ $t('our_products') }}</span>
        </div>
        <div class="home-swiper">
            <Swiper :slides-per-view="this.sliderCount">
                <SwiperSlide class="d-flex justify-content-around" v-for="product of products" :key="product.id">
                    <product-card :product="product"/>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</template>

<script>
import ProductCard from "../UiComponents/ProductCard";
import {Swiper, SwiperSlide} from "swiper/vue";
import 'swiper/swiper-bundle.css'
import Button from "../UiComponents/Button";
import {onMounted} from "vue";
import useSubtypes from '../../composables/subtypes';
import useProducts from '../../composables/products';

export default {
    name: 'HomeSwiper',
    components: {
        Swiper,
        SwiperSlide,
        ProductCard,
        Button,
    },
    data() {
        return {
            sliderCount: "3",
        }
    },
    setup() {
        const {subtypes, getSubtypes} = useSubtypes();
        const {products, getProducts} = useProducts();

        onMounted([
            getSubtypes,
            getProducts,
        ]);

        return {
            subtypes,
            products,
        }
    },
    mounted() {
        window.addEventListener("DOMContentLoaded", this.mySliderSizeHandler);
        window.addEventListener("resize", this.mySliderSizeHandler);
    },
    methods: {
        mySliderSizeHandler(e) {
            if (e.target.innerWidth < 1350) {
                this.sliderCount = "2";
            }
            if (e.target.innerWidth < 992) {
                this.sliderCount = "1";
            }
        }
    },
}
</script>

