<template>
    <div class="button__container">
        <button class="button_search button__web">{{ text }}&nbsp;&nbsp;<img :src="img_src"></button>
    </div>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: String,
        img_src: String,
    }
}

</script>
