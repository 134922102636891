<template>
    <div style="position: relative; overflow: hidden">
        <iframe
            src="https://yandex.com/map-widget/v1/-/CCU5y-eYcA"
            width="80%"
            height="600"
            frameborder="1"
            allowfullscreen="true"
            class="contact_us_map"
        ></iframe>
    </div>
</template>

<script>
export default {
    name: "ContactsMap",
    components: {},
};
</script>

<style scoped>
</style>
