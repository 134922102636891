import {ref} from 'vue';
import axios from "axios";
import {useRouter} from 'vue-router';

export default function useProducts() {

    const products = ref([]);
    const product = ref([]);
    const router = useRouter();
    const product_errors = ref('');

    const getProducts = async () => {
        let response = await axios.get('/api/v1/products')
        products.value = response.data.data.data;
    }

    const getProduct = async (productId) => {
        let response = await axios.get('/api/v1/products/' + productId)
        product.value = response.data.data;
    }

    const storeProduct = async (data) => {
        product_errors.value = '';
        try {
            await axios.post('/api/v1/products', data)
            await router.push({name: 'home'})
        } catch (e) {
            if (e.response.status === 422) {
                for (const key in e.response.data.errors) {
                    product_errors.value += e.response.data.errors[key][0] + '';
                }
            }
        }

    }

    const updateProduct = async (id) => {
        product_errors.value = '';
        try {
            await axios.put('/api/v1/products/' + id, product.value)
            await router.push({name: 'home'})
        } catch (e) {
            if (e.response.status === 422) {
                for (const key in e.response.data.errors) {
                    product_errors.value += e.response.data.errors[key][0] + '';
                }
            }
        }
    }

    const destroyProduct = async (id) => {
        await axios.delete('/api/v1/products/' + id)
    }

    return {
        product,
        products,
        product_errors,
        getProduct,
        getProducts,
        storeProduct,
        updateProduct,
        destroyProduct,
    }
}
