<template>
    <div>
        <div class="position-relative">
            <div class="color_content">
                <div class="color__title">
                    <p class="third-color">{{ $t('auto_paints') }} <span class="fourth-color">{{ $t('shop') }}</span></p>
                </div>
                <div class="color__description">
                    <p>{{ $t('car_colors_text') }}</p>
                </div>
            </div>
            <img class="position-relative car-image" :src="require('/images/home-page/car.jpg').default">
        </div>
    </div>
</template>

<script>

export default {
    name: 'CarColor',
}
</script>
