<template>
    <div class='container'>
        <main>
            <section class='main__goods'>
                <aside class='goods__photos col-sm-4 offset-sm-0 mt-sm-0 mt-5 col-8 offset-2'>
                    <div class='goods__main_preview'>
                        <img :src="product.img" alt="img" data-actived="1">
                    </div>
                </aside>
                <aside class='goods__details col-sm-8 col-12'>
                    <div>
                        <h3 class='goods__company'>{{ getProductSubtype }}</h3>
                        <h1 class='goods__title'>{{ getProductText }}</h1>
                        <p class="goods__company">Производитель: {{ product.manufacturer }}</p>
                        <p class="goods__company">Содержание: {{ product.volume }}</p>
                        <p class='goods__description'>{{ getProductDesc }}</p>
                        <h2 class='goods__sale__price' v-if="product.new_price != null">{{ product.new_price }} ֏</h2>
                        <span class='goods__discount'
                              v-if="product.new_price != null">{{ ((product.old_price - product.new_price) * 100 / product.old_price).toFixed(2) }}%</span>
                        <h6 class='goods__original__price' v-if="product.new_price != null">{{ product.old_price }}֏</h6>
                        <h2 class='goods__sale__price' v-if="product.new_price == null">{{ product.old_price }} ֏</h2>
                        <div class="btns__add">
                            <button class="product__add" @click="addCart(product)">
                                <img :src="require('/images/menu-items/shp.png').default" class="mr-2" alt="shp">{{ $t('add_to_cart') }}
                            </button>
                        </div>
                    </div>
                </aside>
            </section>
            <!--            <section class='main__related'>-->
            <!--                <h1 class='related__title'>Related product</h1>-->
            <!--                <div class='related__goods_viewpoint'>-->
            <!--                    <ul class='related__goods'>-->
            <!--                        <li>-->
            <!--                            <a href="#"><img-->
            <!--                                src="https://images.unsplash.com/photo-1582189336981-104feabcbf93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"></a>-->
            <!--                        </li>-->
            <!--                        <li>-->
            <!--                            <a href="#"><img-->
            <!--                                src="https://images.unsplash.com/photo-1595665094099-10d5b8c7aaeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"></a>-->
            <!--                        </li>-->
            <!--                        <li>-->
            <!--                            <a href="#"><img-->
            <!--                                src="https://images.unsplash.com/photo-1491821893533-80f535044695?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"></a>-->
            <!--                        </li>-->
            <!--                        <li>-->
            <!--                            <a href="#"><img-->
            <!--                                src="https://images.unsplash.com/photo-1470043338005-56c7b76e0a34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"></a>-->
            <!--                        </li>-->
            <!--                        <ol class='related__prev_next' data-order="0">-->
            <!--                            <li>-->
            <!--                                <button class='related__prev'><i class="fa fa-chevron-circle-left"-->
            <!--                                                                 aria-hidden="true"></i></button>-->
            <!--                            </li>-->
            <!--                            <li>-->
            <!--                                <button class='related__next'><i class="fa fa-chevron-circle-right"-->
            <!--                                                                 aria-hidden="true"></i></button>-->
            <!--                            </li>-->
            <!--                        </ol>-->
            <!--                    </ul>-->
            <!--                </div>-->
            <!--            </section>-->
        </main>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'singleProduct',
    data(){
        return {
            'current_language' : this.$root.$i18n.locale
        }
    },
    computed: {
        getProductText() {
            return this.product['text_' + this.current_language] ?? this.product.text_ru
        },
        getProductSubtype() {
            return this.product['subtype_' + this.current_language] ?? this.product.subtype_ru
        },
        getProductDesc() {
            return this.product['description_' + this.current_language] ?? this.product.description_ru
        },
        ...mapGetters({
            product: 'product/currentProduct',
            logged: 'auth/loggedIn'
        })
    },
    methods: {
        ...mapActions({
            getCurrentProduct: "product/currentProduct",
            addToCart: 'product/addToCart'
        }),
        addCart(product) {
            if (this.logged !== false) {
                this.addToCart(product)
            } else {
                this.$router.replace('/login');
            }
        }
    },
    mounted() {
        this.getCurrentProduct(this.$route.params.id)
    },
    watch: {
        '$root.$i18n.locale': {
            handler(lang) {
                this.current_language = lang;
            },
        }
    }
}

</script>
