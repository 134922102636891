<template>
    <div>
        <div class="container catalog_block position-relative">
            <div class="d-flex justify-content-between">
                <div>
                    <h1 class="third-color">{{ $t('catalog') }}</h1>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-around mt-5">
                <CatalogUi/>
            </div>
        </div>
    </div>
</template>

<script>
import Arrow from "../UiComponents/Arrow";
import ProductCard from "../UiComponents/ProductCard";
import CatalogUi from "../UiComponents/CatalogUi";

export default {
    name: 'Catalog',
    components: {
        CatalogUi,
        Arrow,
        ProductCard
    }
}
</script>

