<template>
    <div>
        <LeftSidebar></LeftSidebar>
        <div class="admin_wrapper">
            <router-view/>
        </div>
    </div>
</template>

<script>
import LeftSidebar from "../views/Admin/Left-sidebar";
export default {
    name: "AdminLayout",
    components: {
        LeftSidebar
    }
}
</script>

