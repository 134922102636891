import {createRouter, createWebHistory} from "vue-router";
import middlewarePipeline from "./middlewarePipeline";

import Home from '../views/Home';
import Contacts from "../views/Contacts";
import About from "../views/About";
import Login from "../views/Login";
import SingleProduct from "../views/SingleProduct";
import Cart from "../views/Cart";
import Middleware from "../middleware";
import store from "../store"
import ForgotPassword from "../views/Auth/ForgotPassword";
import ResetPassword from "../views/Auth/ResetPassword";
import Dashboard from "../views/Admin/Dashboard";
import Messages from "../views/Admin/Messages";
import Products from "../views/Admin/Products/Products";
import Sales from "../views/Admin/Sales";
import StoreProduct from "../views/Admin/Products/StoreProduct";
import EditProduct from "../views/Admin/Products/EditProduct";
import Services from "../views/Services";
import LaboratoryPage from "../views/LaboratoryPage";
import ProductsPage from "../views/ProductsPage";
import Discounts from "../views/Discounts";
import AdminCart from "../views/Admin/AdminCart";

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {layout: 'main'},
        component: Home
    },
    {
        path: "/about",
        name: "about",
        meta: {layout: 'main'},
        component: About,
    },
    {
        path: "/single/:id",
        name: "single",
        meta: {layout: 'main'},
        component: SingleProduct,
    },
    {
        path: "/contacts",
        name: "contacts",
        meta: {layout: 'main'},
        component: Contacts,
    },
    {
        path: "/services",
        name: "services",
        meta: {layout: 'main'},
        component: Services,
    },
    {
        path: "/products",
        name: "productsPage",
        meta: {layout: 'main'},
        component: ProductsPage,
    },
    {
        path: "/laboratory",
        name: "laboratoryPage",
        meta: {layout: 'main'},
        component: LaboratoryPage,
    },
    {
        path: "/discounts",
        name: "discounts",
        meta: {layout: 'main'},
        component: Discounts,
    },
    {
        path: "/cart",
        name: "cart",
        meta: {
            layout: 'main',
            middleware: [Middleware.auth]
        },
        component: Cart,
    },
    {
        path: "/login",
        name: "login",
        meta: {
            layout: 'main',
            middleware: [Middleware.guest]
        },
        component: Login,
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        meta: {
            layout: 'main',
            middleware: [Middleware.guest]
        },
        component: ForgotPassword,
    },
    {
        path: "/reset-password/:token",
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            layout: 'main',
            middleware: [Middleware.guest]
        },
    },
    {
        path: "/admin/dashboard",
        name: 'dashboard',
        component: Dashboard,
        meta: {
            layout: 'admin',
            middleware: [Middleware.auth, Middleware.admin]
        },
    },
    {
        path: "/admin/messages",
        name: 'messages',
        component: Messages,
        meta: {
            layout: 'admin',
            middleware: [Middleware.auth, Middleware.admin]
        },
    },
    {
        path: "/admin/products",
        name: 'products',
        component: Products,
        meta: {
            layout: 'admin',
            middleware: [Middleware.auth, Middleware.admin]
        },
    },
    {
        path: "/admin/products/store",
        name: 'storeProduct',
        component: StoreProduct,
        meta: {
            layout: 'admin',
            middleware: [Middleware.auth, Middleware.admin]
        },

    },
    {
        path: "/admin/products/:id",
        name: 'editProduct',
        component: EditProduct,
        meta: {
            layout: 'admin',
            middleware: [Middleware.auth, Middleware.admin]
        },
    },
    {
        path: "/admin/sales",
        name: 'sales',
        component: Sales,
        meta: {
            layout: 'admin',
            middleware: [Middleware.auth, Middleware.admin]
        },
    },
    {
        path: "/admin/cart",
        name: 'adminCart',
        component: AdminCart,
        meta: {
            layout: 'admin',
            middleware: [Middleware.auth, Middleware.admin]
        },
    },
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }

    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
