<template>
    <div class="about_component col-12 d-flex justify-content-around flex-wrap">
        <div class="about_component_car_image col-lg-5 col-12">
            <div class="about_component_car_image-circle overflow-hidden">
                <div class="mb-3">
                    <img style="width: 100%" :src="require('/images/home-page/19199057.jpg').default" alt="">
                </div>
            </div>
        </div>
        <div class="about_component_description col-lg-5 col-12">
            <div>
                <div>
                    <h1 class="about_component_description-title"><span>{{ $t('about_us_first') }}</span>
                        {{ $t('about_us_second') }}</h1>
                    <p class="about_component_description-text">{{ $t('about_us_text') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutCars',
}
</script>
