<template>
    <div class="info__circle">
        <div class="mobile-swiper">
            <Swiper
                style="height: 180px"
                :slides-per-view="this.sliderCount">
                <SwiperSlide class="d-flex justify-content-center">
                    <div
                        @click="makeActive('info')"
                        class="circle" id="info"
                        v-bind:class="{ active_circle: this.isInfoActive, 'not-active': !this.isInfoActive,}">
                        <div><img :src="require('/images/info-block/info.png').default" alt=""/></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide class="d-flex justify-content-center">
                    <div
                        @click="makeActive('comp')"
                        class="circle" id="comp"
                        v-bind:class="{ active_circle: this.isCompActive,'not-active': !this.isCompActive}">
                        <div><img :src="require('/images/info-block/comp.png').default" alt=""/></div>
                    </div>
                </SwiperSlide>

                <SwiperSlide class="d-flex justify-content-center">
                    <div
                        @click="makeActive('paint')"
                        class="circle" id="paint"
                        v-bind:class="{  active_circle: this.isPaintActive, 'not-active': !this.isPaintActive,}">
                        <div><img :src="require('/images/info-block/paint.png').default" alt=""/></div>
                    </div>
                </SwiperSlide>

                <SwiperSlide class="d-flex justify-content-center">
                    <div
                        @click="makeActive('seedling')"
                        class="circle"  id="seedling"
                        v-bind:class="{active_circle: this.isSeedlingActive, 'not-active': !this.isSeedlingActive, }">
                        <div><img :src="require('/images/info-block/seedling.png').default" alt=""/></div>
                    </div>
                </SwiperSlide>

                <SwiperSlide class="d-flex justify-content-center">
                    <div
                        @click="makeActive('book')"
                        class="circle" id="book"
                        v-bind:class="{active_circle: this.isBookActive,'not-active': !this.isBookActive,}">
                        <div><img :src="require('/images/info-block/book.png').default" alt=""/></div>
                    </div>
                </SwiperSlide>

                <SwiperSlide class="d-flex justify-content-center">
                    <div
                        @click="makeActive('shield')"
                        class="circle" id="shield"
                        v-bind:class="{ active_circle: this.isShieldActive,'not-active': !this.isShieldActive,}">
                        <div><img :src="require('/images/info-block/shield.png').default" alt=""/></div>
                    </div>
                </SwiperSlide>

                <SwiperSlide class="d-flex justify-content-center">
                    <div
                        @click="makeActive('search')"
                        class="circle" id="search"
                        v-bind:class="{active_circle: this.isSearchActive,'not-active': !this.isSearchActive,}">
                        <div><img :src="require('/images/info-block/search.png').default" alt=""/></div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        <div class="info__text">
            <div>
                <h1 v-if="texts[0].connected == show_text" class="info__title">
                    {{ texts[0].title }}
                </h1>
                <h1 v-if="texts[1].connected == show_text" class="info__title">
                    {{ texts[1].title }}
                </h1>
                <h1 v-if="texts[2].connected == show_text" class="info__title">
                    {{ texts[2].title }}
                </h1>
                <h1 v-if="texts[3].connected == show_text" class="info__title">
                    {{ texts[3].title }}
                </h1>
                <h1 v-if="texts[4].connected == show_text" class="info__title">
                    {{ texts[4].title }}
                </h1>
                <h1 v-if="texts[5].connected == show_text" class="info__title">
                    {{ texts[5].title }}
                </h1>
                <h1 v-if="texts[6].connected == show_text" class="info__title">
                    {{ texts[6].title }}
                </h1>
            </div>
            <div>
                <p v-if="texts[0].connected == show_text" class="info__description">
                    {{ texts[0].description }}
                </p>
                <p v-if="texts[1].connected == show_text" class="info__description">
                    {{ texts[1].description }}
                </p>
                <p v-if="texts[2].connected == show_text" class="info__description">
                    {{ texts[2].description }}
                </p>
                <p v-if="texts[3].connected == show_text" class="info__description">
                    {{ texts[3].description }}
                </p>
                <p v-if="texts[4].connected == show_text" class="info__description">
                    {{ texts[4].description }}
                </p>
                <p v-if="texts[5].connected == show_text" class="info__description">
                    {{ texts[5].description }}
                </p>
                <p v-if="texts[6].connected == show_text" class="info__description">
                    {{ texts[6].description }}
                </p>
            </div>
        </div>
        <div class="info__images">
            <!--            <div>-->
            <!--                <p class="circle__title">{{$t('benefits_of_acc')}}</p>-->
            <!--            </div>-->
            <div class="info__representation">
                <div
                    @click="makeActive('comp')"
                    class="circle comp"
                    v-bind:class="{
            active_circle: this.isCompActive,
            'not-active': !this.isCompActive,
          }"
                    id="comp"
                >
                    <div><img :src="require('/images/info-block/comp.png').default" alt=""/></div>
                </div>

                <div
                    @click="makeActive('paint')"
                    class="circle paint"
                    v-bind:class="{
            active_circle: this.isPaintActive,
            'not-active': !this.isPaintActive,
          }"
                    id="paint"
                >
                    <div><img :src="require('/images/info-block/paint.png').default" alt=""/></div>
                </div>

                <div
                    @click="makeActive('info')"
                    class="circle info"
                    v-bind:class="{
            active_circle: this.isInfoActive,
            'not-active': !this.isInfoActive,
          }"
                    id="info"
                >
                    <div><img :src="require('/images/info-block/info.png').default" alt=""/></div>
                </div>

                <div
                    @click="makeActive('seedling')"
                    class="circle seedling"
                    v-bind:class="{
            active_circle: this.isSeedlingActive,
            'not-active': !this.isSeedlingActive,
          }"
                    id="seedling"
                >
                    <div><img :src="require('/images/info-block/seedling.png').default" alt=""/></div>
                </div>

                <div
                    @click="makeActive('book')"
                    class="circle book"
                    v-bind:class="{
            active_circle: this.isBookActive,
            'not-active': !this.isBookActive,
          }"
                    id="book"
                >
                    <div><img :src="require('/images/info-block/book.png').default" alt=""/></div>
                </div>

                <div
                    @click="makeActive('shield')"
                    class="circle shield"
                    v-bind:class="{
            active_circle: this.isShieldActive,
            'not-active': !this.isShieldActive,
          }"
                    id="shield"
                >
                    <div><img :src="require('/images/info-block/shield.png').default" alt=""/></div>
                </div>

                <div
                    @click="makeActive('search')"
                    class="circle search"
                    v-bind:class="{
            active_circle: this.isSearchActive,
            'not-active': !this.isSearchActive,
          }"
                    id="search"
                >
                    <div><img :src="require('/images/info-block/search.png').default" alt=""/></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";

export default {
    name: "InfoCircle",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            show_text: "info",
            texts: [
                {
                    title: this.$t('computer_selection_enamels'),
                    description: this.$t('computer_selection_enamels_text'),
                    connected: "comp",
                },
                {
                    title: this.$t('finished_paints'),
                    description: this.$t('finished_paints_text'),
                    connected: "paint",
                },
                {
                    title: this.$t('database'),
                    description: this.$t('database_text'),
                    connected: "info",
                },
                {
                    title: this.$t('quality_control'),
                    description: this.$t('quality_control_text'),
                    connected: "seedling",
                },
                {
                    title: this.$t('warranty'),
                    description: this.$t('warranty_text'),
                    connected: "book",
                },
                {
                    title: this.$t('ease_of_use'),
                    description: this.$t('ease_of_use_text'),
                    connected: "shield",
                },
                {
                    title: this.$t('high_coverage'),
                    description: this.$t('high_coverage_text'),
                    connected: "search",
                },
            ],
            isInfoActive: true,
            isCompActive: false,
            isBookActive: false,
            isSeedlingActive: false,
            isPaintActive: false,
            isShieldActive: false,
            isSearchActive: false,
        };
    },
    methods: {
        makeActive(name) {
            switch (name) {
                case "comp":
                    this.show_text = "comp";
                    this.isCompActive = true;
                    this.isPaintActive = false;
                    this.isSeedlingActive = false;
                    this.isInfoActive = false;
                    this.isShieldActive = false;
                    this.isSearchActive = false;
                    this.isBookActive = false;
                    break;
                case "paint":
                    this.show_text = "paint";
                    this.isSeedlingActive = false;
                    this.isPaintActive = true;
                    this.isCompActive = false;
                    this.isInfoActive = false;
                    this.isBookActive = false;
                    this.isShieldActive = false;
                    this.isSearchActive = false;
                    break;
                case "seedling":
                    this.show_text = "seedling";
                    this.isSeedlingActive = true;
                    this.isPaintActive = false;
                    this.isCompActive = false;
                    this.isInfoActive = false;
                    this.isBookActive = false;
                    this.isShieldActive = false;
                    this.isSearchActive = false;
                    break;
                case "info":
                    this.show_text = "info";
                    this.isSeedlingActive = false;
                    this.isInfoActive = true;
                    this.isCompActive = false;
                    this.isPaintActive = false;
                    this.isBookActive = false;
                    this.isShieldActive = false;
                    this.isSearchActive = false;
                    break;
                case "book":
                    this.show_text = "book";
                    this.isSeedlingActive = false;
                    this.isBookActive = true;
                    this.isCompActive = false;
                    this.isPaintActive = false;
                    this.isInfoActive = false;
                    this.isShieldActive = false;
                    this.isSearchActive = false;
                    break;
                case "shield":
                    this.show_text = "shield";
                    this.isSeedlingActive = false;
                    this.isShieldActive = true;
                    this.isCompActive = false;
                    this.isPaintActive = false;
                    this.isInfoActive = false;
                    this.isSearchActive = false;
                    this.isBookActive = false;
                    break;
                case "search":
                    this.show_text = "search";
                    this.isSeedlingActive = false;
                    this.isSearchActive = true;
                    this.isCompActive = false;
                    this.isPaintActive = false;
                    this.isInfoActive = false;
                    this.isShieldActive = false;
                    this.isBookActive = false;
                    break;
            }
        },
        mySliderSizeHandler(e) {
            if (e.target.innerWidth < 767) {
                this.sliderCount = "2";
            }
        }
    },
};
</script>

