import instance from '../../../axios/axios-instance'

const state = {
    errors: [],
    messages: [],
    page: 1,
    limit: 10,
    totalPages: 0,
}

const actions = {

    getMessages(ctx) {
        return new Promise((resolve, reject) => {
            instance.get('/api/v1/contact_messages',{
                params: {
                    page: state.page,
                }
            })
                .then((response) => {
                    ctx.commit('setTotalPages', Math.ceil(response.data.data.total / state.limit))
                    ctx.commit('setMessages', response.data.data.data)
                }).catch((error) => {
                reject(error)
            });
        });
    },

    sendMessage(ctx, message) {
        return new Promise((resolve, reject) => {
            instance
                .post("/api/v1/contact_messages", {
                    name: message.name,
                    email: message.email,
                    message: message.message
                })
                .then(response => {
                    if (response.data) {
                        message.name = "";
                        message.email = "";
                        message.message = "";
                        ctx.commit('setErrors', [])
                        resolve(response)
                    } else {
                        reject(response)
                    }
                    ctx.commit('setErrors', [])
                }).catch((error) => {
                if (error.response.status === 422) {
                    ctx.commit('setErrors', error.response.data.errors)
                }
            })
        })
    },
    DeleteMessage(ctx, id) {
        return new Promise((resolve, reject) => {
            instance.delete('/api/v1/contact_messages/' + id)
                .then((response) => {
                    let result = state.messages.filter((item) => {
                        return item.id != id
                    })
                    ctx.commit('setMessages', result)
                    resolve(response)
                }).catch((error) => {
                reject(error)
            });
        });
    },
}

const mutations = {
    setErrors(state, errors) {
        state.errors = errors
    },
    setMessages(state, messages) {
        state.messages = messages
    },
    setPage(state, page) {
        state.page = page
    },
    setTotalPages(state, totalPages) {
        state.totalPages = totalPages
    },
}

const getters = {
    errors(state) {
        return state.errors
    },
    messages(state) {
        return state.messages
    },
    page(state){
        return state.page
    },
    totalPages(state){
        return state.totalPages
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
