<template>
    <div class="laboratory">
        <div class="sec">
            <p class="laboratory__title"><span>{{ $t('our') }}</span> {{ $t('our_laboratory') }}</p>
            <p class="laboratory__description">{{$t('laboratory_text')}}</p>
        </div>
        <div class="one">
            <div class="pink_circle">
                <img :src="require('/images/info-block/temp.png').default" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Laboratory',
}
</script>

