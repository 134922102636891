<template>
    <div class="col-10 offset-1 mt-3 mb-3">
        <div class="d-flex justify-content-around col-12 flex-wrap">
            <transition-group name="list" mode="easy-in" appear>
                <div class="product__card__admin col-3 mt-3 mb-3 ml-3" :key="0">
                    <div class="product__card-logo-block">
                        <div class="product__card-logo"><img :src="require('/images/logo.png').default"></div>
                    </div>
                    <div class="product_presentation">
                        <router-link to="/admin/products/store" class="product_img_admin">
                            <img :src="require('/images/product-images/plusss.png').default">
                        </router-link>
                    </div>
                    <div class="product__description">
                        <div>
                            <p>Ստեղծել նոր ապրանք</p>
                        </div>
                    </div>
                </div>
                <div class="product__card__admin mt-3 mb-3 ml-3 col-3" v-for="product in products" :key="product.id">
                    <div class="product__card-logo-block">
                        <div class="product__card-logo"><img :src="require('/images/logo.png').default"></div>
                    </div>
                    <div class="product_presentation">
                        <div class="product_img">
                            <img :src="product.img">
                        </div>
                    </div>
                    <div class="product__description">
                        <div>
                            <p>{{ product.text_hy }}</p>
                        </div>
                    </div>
                    <div class="product__buttons">
                        <div>
                            <button @click="editProduct(product.id)" class="btn btn-outline-success">Edit</button>
                        </div>
                        <div>
                            <button @click="deleteProduct(product.id)" class="btn btn-outline-danger">Delete</button>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
        <div class="page__wrapper">
            <div
                v-for="pageNumber in totalPages"
                :key="pageNumber"
                class="__page ml-3"
                :class="{
              'current-page': page === pageNumber
            }"
                @click="changePage(pageNumber)"
            >
                {{ pageNumber }}
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: "Products",
    computed: {
        ...mapState({
            page: state => state.product.page,
            limit: state => state.product.limit,
            totalPages: state => state.product.totalPages,
        }),
        ...mapGetters({
            subtypes: "subtype/subtypes",
            products: "product/products"
        }),
    },
    methods: {
        ...mapActions({
            getSubtypes: "subtype/getSubtypes",
            getProducts: "product/getProducts"
        }),
        editProduct(id) {
            this.$store.dispatch('product/showProduct', id)
        },
        deleteProduct(id) {
            if (confirm('Հեռացնել ապրանքը?')) {
                this.$store.dispatch('product/destroyProduct', id)
            }
        },
        changePage(pageNumber) {
            this.$store.commit('product/setPage', pageNumber)
        }
    },
    mounted() {
        this.getSubtypes();
        this.getProducts()
    },
    watch: {
        page() {
            this.getProducts();
        }
    }
}
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}

.__page{
    color: black !important;
}

.current-page{
    color: teal !important;
}

</style>
